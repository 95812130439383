import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardMain from "../../../../../../models/cardMain";
import { CardSpendLimitType } from "../../../../../../models/card.constants";
import CurrencyCode from "../../../../../../components/currency-code";
import CustomSelect from "../../../../../../components/dropdown/custom-select";
import MoneyInput from "../../../../../../components/inputs/money-input";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
import formatNumber from "../../../../../../utils/formatNumber";
import { useMoneyToNumber } from "../../../../../../hooks/useMoneyToNumber";
import useSpendLimit from "../../../Hooks/State/useSpendLimit";

interface IUpdateSpendingLimit {
    isSelectedCardDetailsOpen: boolean;
    data: CardMain;
    handleBack: () => void;
}

function UpdateSpendingLimit({ isSelectedCardDetailsOpen, data, handleBack }: IUpdateSpendingLimit): JSX.Element {
    const { createRequest: card, cardSpend } = data;
    const { isSpendingLimitLoading, isRemoveSpendingLimitLoading, handleUpdateSpendingLimit, handleRemoveSpendingLimit } = useSpendLimit();
    // {
    // onComplete: handleBack,
    // }

    const divRef = useRef<HTMLDivElement | null>(null);

    const [frequency, setFrequency] = useState<CardSpendLimitType | null>(cardSpend?.type || null);
    const [spendingLimit, setSpendingLimit] = useState<number | null>(Number(cardSpend?.limit) || 0);
    // const [showError, setShowError] = useState<boolean>(false);
    const [isSpendLimitOn, setIsSpendLimitOn] = useState<boolean>(true);

    useEffect(() => {
        divRef.current?.scrollIntoView();
        if (!isSpendLimitOn) {
            setFrequency(null);
            setSpendingLimit(0);
        }
    }, [isSpendLimitOn]);

    const handleAmountChange = useCallback((e: string | number) => {
        const numberValue = useMoneyToNumber(e?.toString());
        setSpendingLimit(numberValue);
        // if (numberValue > 450000) {
        // setShowError(true);
        // setSpendingLimit(450000);
        // return;
        // }
        // setShowError(false);
    }, []);

    return (
        <div className="mt-4 w-full" data-type={isSelectedCardDetailsOpen ? "card-active" : "card"} ref={divRef}>
            <div
                className="flex w-full max-w-md items-center justify-between space-y-3"
                data-type={isSelectedCardDetailsOpen ? "card-active" : "card"}
            >
                <div className="flex w-full flex-col space-y-2">
                    <h3 className="text-base font-medium leading-none text-black">Spending Limit</h3>
                    <p className="text-sm font-normal text-black-secondary">Set spending limit and frequency for the card</p>
                </div>
                <div>
                    <ToggleSwitch
                        isActive={isSpendLimitOn}
                        onToggle={() => {
                            setIsSpendLimitOn((prev) => {
                                if (prev && cardSpend) {
                                    void handleRemoveSpendingLimit({ createRequestId: card.id });
                                }
                                return !prev;
                            });
                        }}
                    />
                </div>
            </div>
            <div className="flex w-full flex-col justify-between">
                <div className="flex flex-col items-start space-y-2 pt-6 text-base font-normal md:items-center md:px-0 lg:items-center lg:px-0">
                    <div className="flex w-full flex-col space-y-4">
                        <div className="flex w-full flex-col items-start justify-start space-y-4 lg:flex-row">
                            <div className="relative flex w-full flex-col space-y-2">
                                <CustomSelect
                                    size={"lg"}
                                    value={frequency}
                                    placeholder="Frequency"
                                    options={[
                                        {
                                            text: "Daily",
                                            subtext: "Limit resets every day (00:00 -11:59pm)",
                                            value: CardSpendLimitType.DAILY_ACCUMULATIVE,
                                        },
                                        {
                                            text: "Weekly",
                                            subtext: "Limit resets every Monday (Mon - Sun)",
                                            value: CardSpendLimitType.WEEKLY_ACCUMULATIVE,
                                        },
                                        {
                                            text: "Monthly",
                                            subtext: "Limit resets every calendar month",
                                            value: CardSpendLimitType.MONTHLY_ACCUMULATIVE,
                                        },
                                    ]}
                                    onSelect={(e) => {
                                        if (e) setFrequency(e);
                                    }}
                                    isDisabled={!isSpendLimitOn}
                                    big
                                    fitHeight
                                />
                            </div>
                        </div>
                        <div className="relative flex w-full flex-col items-start justify-start space-y-2">
                            <MoneyInput
                                placeholder="Spending Limit"
                                icon={<CurrencyCode />}
                                value={String(spendingLimit)}
                                onChange={handleAmountChange}
                                isDisabled={!isSpendLimitOn}
                                isControlledInput
                            />

                            {!!spendingLimit && (
                                <div className="flex items-center justify-center space-x-2 rounded-lg bg-grey-backdrop px-1 py-1 text-black-secondary">
                                    <p className="text-xs">
                                        You cannot spend more than{" "}
                                        <span className="font-bold">
                                            <CurrencyCode />
                                            {formatNumber(spendingLimit)}
                                        </span>{" "}
                                        {frequency !== CardSpendLimitType.SINGLE_PAYMENT ? "each" : ""}{" "}
                                        <span className="font-bold">
                                            {frequency === CardSpendLimitType.DAILY_ACCUMULATIVE
                                                ? "Day"
                                                : frequency === CardSpendLimitType.WEEKLY_ACCUMULATIVE
                                                  ? "Week"
                                                  : frequency === CardSpendLimitType.MONTHLY_ACCUMULATIVE
                                                    ? "Month"
                                                    : "Once"}
                                        </span>{" "}
                                        on the card
                                    </p>
                                </div>
                            )}
                            {/* {showError && (
								<MessageToasts
									toastMessage={
										<p>
											Maximum amount of <CurrencyCode />
											450,000.00
										</p>
									}
									toastType={ToastType.ERROR}
								/>
							)} */}
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-row items-center justify-center space-x-4 pt-6">
                    <ButtonComp size="lg" color="grey" buttonType="secondary" onClick={handleBack}>
                        Cancel
                    </ButtonComp>

                    <ButtonComp
                        size="lg"
                        color="black"
                        buttonType="primary"
                        disable={!spendingLimit || !frequency}
                        isLoading={isSpendingLimitLoading || isRemoveSpendingLimitLoading}
                        onClick={() => {
                            if (spendingLimit && frequency && data?.createRequest.id)
                                void handleUpdateSpendingLimit({
                                    createRequestId: data?.createRequest.id,
                                    limit: spendingLimit,
                                    type: frequency,
                                });
                        }}
                    >
                        Update Spending Limit
                    </ButtonComp>
                </div>
            </div>
        </div>
    );
}
export default UpdateSpendingLimit;
