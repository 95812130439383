import { DeclineReason, SelectedTransaction } from "../../hooks/state/pending-approval/usePendingPayments.types";
import React, { useEffect, useLayoutEffect } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
// import LencoSpinner from "../../../../../components/spinner";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Money from "../../../../../components/money";
import Otp from "../../../../../components/otp";
import { OtpActivityType } from "../../../../../helpers/AppConstants";
import { useAppSelector } from "../../../../../redux/hooks";
import useBulkCancelPayment from "../../hooks/state/pending-approval/useBulkCancelPayment";
import useBulkPayment from "../../hooks/state/pending-approval/useBulkPayment";

interface BulkProcessingModalProps {
    active: boolean;
    data: SelectedTransaction[];
    toggler: () => void;
    handleUpdatePendingPaymentsState: () => void;
}
function BulkProcessingModal(props: BulkProcessingModalProps): JSX.Element {
    const { active, data, toggler, handleUpdatePendingPaymentsState } = props;

    const isAuthAppSetup = useAppSelector((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    const numberOfApprovedPayments = data.filter((el) => el.isApproved).length;
    const totalPriceOfApprovedPayments = data
        .filter((el) => el.isApproved)
        .reduce(function (a, b) {
            return a + b.transaction.amount;
        }, 0);

    const numberOfDeclinedPayments = data.filter((el) => el.isDeclined).length;
    const totalPriceOfDeclinedPayments = data
        .filter((el) => el.isDeclined)
        .reduce(function (a, b) {
            return a + b.transaction.amount;
        }, 0);

    const numberOfCancelledPayments = data.filter((el) => el.isCancelled).length;
    const totalPriceOfCancelledPayments = data
        .filter((el) => el.isCancelled)
        .reduce(function (a, b) {
            return a + b.transaction.amount;
        }, 0);

    const {
        otp,
        bulkPaymentError,
        isInitOTPLoading,
        isBulkPaymentLoading,

        handleReset,
        handleOTPInit,
        handleOtpChange,
        handleBulkProcessing,
    } = useBulkPayment({
        active,
        onComplete: () => {
            toggler();
            handleUpdatePendingPaymentsState();
        },
    });

    const { isBulkCancelPaymentLoading, handleBulkCancelPayment } = useBulkCancelPayment({
        onComplete:
            numberOfCancelledPayments > 0
                ? undefined
                : () => {
                      toggler();
                      handleUpdatePendingPaymentsState();
                  },
    });

    useLayoutEffect(() => {
        if (!active) {
            handleReset();
            return;
        }
        void handleOTPInit({ action: OtpActivityType.PROCESS_TRANSACTION });
    }, [active, handleOTPInit]);

    useEffect(() => {
        if (!otp || otp.length < 6) return;

        const approvedTransaction = data.filter((el) => !el.isCancelled);
        const cancelledTransaction = data.filter((el) => el.isCancelled);

        void handleBulkProcessing({
            transactions: approvedTransaction.map((el) => ({ id: el.transaction.id, approve: el.isApproved, reason: el.declinedReason })),
            otp,
        });
        if (numberOfCancelledPayments > 0) {
            void handleBulkCancelPayment({
                transactionIds: cancelledTransaction.map((el) => el.transaction.id),
                reason: DeclineReason.OTHERS,
            });
        }
    }, [otp, numberOfCancelledPayments]);

    return (
        <div>
            <Modal size="md" active={active} toggler={toggler}>
                <ModalHeader onClose={toggler}>
                    <h3>Process Payment</h3>
                    <p className="pt-2 font-normal text-black-tertiary">
                        {" "}
                        {isInitOTPLoading ? (
                            <div className="flex w-full flex-row items-start justify-start space-x-1 pb-4">
                                {/* <LencoSpinner size="sm" />  */}
                                <span className="text-left text-sm">Generating OTP...</span>
                            </div>
                        ) : (
                            <div className="flex w-full flex-col space-y-2">
                                <span className="text-left text-sm text-black-tertiary">
                                    Enter the OTP sent to your email address to
                                    {isAuthAppSetup && "Enter the OTP generated from your token App to "}
                                    {!isAuthAppSetup && "Enter the OTP sent to your email address to "}
                                    <br />
                                    {numberOfApprovedPayments > 0 && (
                                        <>
                                            <span className="font-bold">
                                                approve <span>{numberOfApprovedPayments}</span>
                                            </span>
                                            <span> payment{numberOfApprovedPayments > 1 ? "s" : ""} of </span>
                                            <span>
                                                <Money amount={totalPriceOfApprovedPayments} />
                                            </span>
                                        </>
                                    )}
                                    {numberOfDeclinedPayments > 0 && (
                                        <>
                                            <span>{numberOfCancelledPayments > 0 ? ", " : " and "}</span>
                                            <span className="font-bold">
                                                decline <span>{numberOfDeclinedPayments}</span>
                                            </span>
                                            <span> payment{numberOfDeclinedPayments > 1 ? "s" : ""} of </span>
                                            <span>
                                                <Money amount={totalPriceOfDeclinedPayments} />
                                            </span>
                                        </>
                                    )}
                                    {numberOfCancelledPayments > 0 && (
                                        <>
                                            <span> and </span>
                                            <span className="font-bold">
                                                cancel <span>{numberOfCancelledPayments}</span>
                                            </span>
                                            <span> payment{numberOfCancelledPayments > 1 ? "s" : ""} of </span>
                                            <span>
                                                <Money amount={totalPriceOfCancelledPayments} />
                                            </span>
                                        </>
                                    )}
                                </span>
                            </div>
                        )}
                    </p>
                </ModalHeader>
                <ModalBody>
                    <Otp
                        value={otp || ""}
                        active={active}
                        isError={bulkPaymentError}
                        onChange={handleOtpChange}
                        resendMins={3}
                        handleResend={() => void handleOTPInit({ action: OtpActivityType.PROCESS_TRANSACTION })}
                        isResendOtpLoading={isInitOTPLoading}
                        canResendOtp={!isAuthAppSetup}
                    />
                </ModalBody>
                <ModalFooter>
                    <ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={() => toggler()}>
                        <span>cancel</span>
                    </ButtonComp>

                    <ButtonComp
                        type="button"
                        color="black"
                        ripple="light"
                        buttonType="primary"
                        isLoading={isBulkPaymentLoading || isBulkCancelPaymentLoading}
                        disable={(otp?.length || 0) < 6 || isInitOTPLoading || isBulkPaymentLoading || isBulkCancelPaymentLoading}
                        func={() =>
                            void handleBulkProcessing({
                                transactions: data.map((el) => ({ id: el.transaction.id, approve: el.isApproved, reason: el.declinedReason })),
                                otp: otp || undefined,
                            })
                        }
                    >
                        <span>Authorize Payment</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default BulkProcessingModal;
