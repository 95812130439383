import { TableColumnType } from "../../../../../../components/Table/Type/TableColumnType";
import { TableHead } from "../../../../../../components/Table/Type/HeadRow";

export const headerData: TableHead = [
    { text: "Account", pos: 1, headType: TableColumnType.LOGO_TREND_SUBTEXT_FROM_TEXT, width: "20%" },
    { text: "To/From", pos: 2, headType: TableColumnType.LOGO_FROM_TEXT, width: "30%" },
    { text: "Type", pos: 3, headType: TableColumnType.TREND_LOGO, width: "15%" },
    { text: "Amount", pos: 4, headType: TableColumnType.AMOUNT, width: "15%" },
    { text: "Balance", pos: 5, headType: TableColumnType.AMOUNT, width: "20%" },
];

export const SingleTransactionTableHead: TableHead = [
    { text: "To/From", pos: 2, headType: TableColumnType.LOGO_FROM_TEXT, width: "40%" },
    { text: "Type", pos: 3, headType: TableColumnType.TREND_LOGO, width: "15%" },
    { text: "Amount", pos: 4, headType: TableColumnType.AMOUNT, width: "20%" },
    { text: "Balance", pos: 5, headType: TableColumnType.AMOUNT, width: "25%" },
];
