import { useEffect, useRef } from "react";

import OtpInput from "react-otp-input";
import ResendOtp from "./components/resend-otp";

interface Props {
    value: string | null | undefined;
    active?: boolean;
    isError?: boolean | string | null;
    isSmall?: boolean;
    resendMins?: 1 | 3;
    canResendOtp?: boolean;
    isResendOtpLoading?: boolean;

    onChange: (e: string) => void | undefined;
    handleResend?: () => void;
}

function Otp(props: Props): JSX.Element {
    const { active = true } = props;
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        if (!inputRefs.current) return;
        inputRefs.current[0]?.focus();
    }, [inputRefs.current]);

    return (
        <>
            <div className="flex w-full flex-col items-center justify-center gap-5">
                <OtpInput
                    renderInput={({ ..._props }) => <input {..._props} disabled={props.isResendOtpLoading} />}
                    value={props.value || undefined}
                    onChange={props.onChange}
                    placeholder=""
                    inputStyle={
                        "border rounded-lg focus:outline-none !w-full !h-full text-xl lg:text-2xl !aspect-square p-[5%] lg:p-[unset] lg:max-w-20 lg:max-h-20 " +
                        `${props.isError ? "border-error" : "border-black-quin"}`
                    }
                    //use tel instead of number because number makes the arrows appear
                    inputType="tel"
                    numInputs={6}
                    containerStyle={"flex w-full items-center justify-between gap-2 "}
                    shouldAutoFocus={active}
                />
                {(props.canResendOtp || props.isError) && (
                    <div className="flex min-h-6 w-full items-center justify-between space-x-2">
                        {active && props.canResendOtp && props.handleResend && (
                            <ResendOtp
                                isLoading={props.isResendOtpLoading || false}
                                handleResend={props.handleResend}
                                resendMins={props.resendMins}
                            />
                        )}
                        {typeof props.isError === "string" && <p className="max-w-50% text-wrap break-words text-sm text-error">{props.isError}</p>}
                    </div>
                )}
            </div>
        </>
    );
}

export default Otp;
