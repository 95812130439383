import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import LencoSpinner from "../../../../../components/spinner";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import Otp from "../../../../../components/otp";
import UserAccount from "../../../../../models/userAccount";
import useActivateAccountState from "../../Hooks/State/useActivateAccountState";
import { useAppSelector } from "../../../../../redux/hooks";
import { useEffect } from "react";

interface ActivateAccountSectionProps {
    data: UserAccount | null;
    active: boolean;
    toggler: () => void;
    onCompleteFunc: (account: UserAccount) => void;
}

function ActivateAccountSection(props: ActivateAccountSectionProps): JSX.Element {
    const { active, data, toggler, onCompleteFunc } = props;
    const {
        otp,
        initOTPError,
        isInitOTPLoading,
        isResendInitOTPLoading,
        isActivateAccountFinishError,
        activateAccountFinishResponse,
        isActivateAccountFinishLoading,
        handleOTPInit,
        handleOtpChange,
        handleResendOTPInit,
        handleActivateAccountFinish,
    } = useActivateAccountState(active);

    const isAuthAppSetup = useAppSelector((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    useEffect(() => {
        if (!data || !active) return;
        void handleOTPInit({ userAccountId: data.id });
    }, [data, active, handleOTPInit]);

    useEffect(() => {
        if (!otp || otp.length < 6 || !data) return;
        void handleActivateAccountFinish({ userAccountId: data.id, otp });
    }, [data, handleActivateAccountFinish, otp]);

    useEffect(() => {
        if (!activateAccountFinishResponse || !data) return;
        onCompleteFunc(data);
        toggler();
    }, [activateAccountFinishResponse, data, toggler, onCompleteFunc]);

    return (
        <>
            <ModalHeader onClose={toggler}>Activate Account</ModalHeader>
            <ModalBody>
                <div className="relative flex w-full flex-col">
                    {(initOTPError || isActivateAccountFinishError) && (
                        <div className="mb-4 flex w-full items-center justify-center">
                            <ErrorToast error={initOTPError || isActivateAccountFinishError || ""} />
                        </div>
                    )}
                    {!initOTPError && (
                        <>
                            {isInitOTPLoading ? (
                                <div className="flex w-full flex-row items-start justify-start space-x-1 pb-4 text-sm">
                                    <LencoSpinner size={"sm"} /> <span className="text-left">Generating OTP...</span>
                                </div>
                            ) : (
                                <div className="flex w-full flex-col items-start justify-start space-y-2 text-left">
                                    <span className="text-base font-medium">This is a one-time action on this account.</span>
                                    <span className="text-sm font-normal">
                                        To enable {data && data.subAccountShortName} for payout,
                                        {isAuthAppSetup && "Enter the OTP generated from your token App "}
                                        {!isAuthAppSetup && "Enter the OTP sent to your email address "}
                                        {/* we sent a one-time password to your phone number  */}
                                        to activate the account with our bank partner.
                                        {/* we sent a one-time password to your phone number to activate
                                        the account with our bank partner. */}
                                    </span>
                                </div>
                            )}
                            <div className="flex w-full flex-col items-start justify-start pt-4">
                                <Otp
                                    value={otp || undefined}
                                    isError={isActivateAccountFinishError}
                                    onChange={handleOtpChange}
                                    handleResend={() => void handleResendOTPInit({ userAccountId: data?.id || "" })}
                                    isResendOtpLoading={isResendInitOTPLoading}
                                    resendMins={3}
                                    canResendOtp
                                />
                            </div>
                        </>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonComp
                    type="button"
                    ripple="light"
                    buttonType="secondary"
                    color="grey"
                    func={() => {
                        toggler();
                    }}
                >
                    <span>Back</span>
                </ButtonComp>

                <ButtonComp
                    type="submit"
                    color="blue"
                    ripple="light"
                    buttonType="primary"
                    isLoading={isActivateAccountFinishLoading}
                    disable={!isActivateAccountFinishLoading}
                >
                    <div className="flex w-full items-center justify-center">
                        <span>Authorize</span>
                    </div>
                </ButtonComp>
            </ModalFooter>
        </>
    );
}

export default ActivateAccountSection;
