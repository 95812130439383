import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useRef } from "react";

import { ApiAccessScope } from "../../../../../../models/zambia/lencoPay/apiAccess.constant";
import ButtonComp from "../../../../../../components/button/ButtonComp";
import FullPageTray from "../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
import { updateScopesDefaultSetupConfiguration } from "../../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

const DATA_TYPE = "api-access-key-channels";

interface Props {
    active: boolean;
    toggler: () => void;
}

function DefaultSetupChannelsTray(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const zambiaLencoPayDefaultSetup = useAppSelector((state) => state.zambiaDefaultSetup.defaultSetup);
    const formikRef = useRef<FormikProps<{ scopes: ApiAccessScope[] }> | null>(null);

    const INITIAL_FORM_STATE = {
        scopes: zambiaLencoPayDefaultSetup?.scopes || [],
    };

    const FORM_VALIDATION = Yup.object().shape({
        scopes: Yup.array()
            .of(Yup.mixed<ApiAccessScope>().oneOf(Object.values(ApiAccessScope)).required())
            // .min(1)
            .required(),
    });

    // useEffect(() => {
    // formikRef.current?.getFieldHelpers("scopes").setValue(props.active ? props.scopes : []);
    // }, [props.active]);

    return (
        <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
            <FullPageTrayHeader dataType={DATA_TYPE} subTitle="Select what channel the API can access" toggler={props.toggler}>
                Channels
            </FullPageTrayHeader>
            <Formik
                innerRef={formikRef}
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    dispatch(updateScopesDefaultSetupConfiguration(values.scopes));
                    props.toggler();
                }}
                enableReinitialize
                validateOnMount
                validateOnChange
            >
                {(formik) => {
                    return (
                        <Form className="w-full">
                            <FullPageTrayBody dataType={DATA_TYPE}>
                                <div className="flex w-full flex-col space-y-8">
                                    <div className="space-y-4">
                                        <h5 className="text-lg font-medium text-black-secondary">Collections</h5>
                                        <div className="space-y-6">
                                            <div className="flex items-center justify-between">
                                                <p className="text-base text-black-secondary">Mobile Money</p>
                                                <ToggleSwitch
                                                    isActive={formik.values.scopes.some(
                                                        (_scope) => _scope === ApiAccessScope.COLLECTIONS_MOBILE_MONEY
                                                    )}
                                                    onToggle={() => {
                                                        const values = formik.values.scopes.some(
                                                            (_scope) => _scope === ApiAccessScope.COLLECTIONS_MOBILE_MONEY
                                                        )
                                                            ? formik.values.scopes.filter(
                                                                  (_scope) => _scope !== ApiAccessScope.COLLECTIONS_MOBILE_MONEY
                                                              )
                                                            : [...formik.values.scopes, ApiAccessScope.COLLECTIONS_MOBILE_MONEY];
                                                        formik.getFieldHelpers("scopes").setValue([...values]);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <p className="text-base text-black-secondary">Cards</p>
                                                <ToggleSwitch
                                                    isActive={formik.values.scopes.some((_scope) => _scope === ApiAccessScope.COLLECTIONS_CARD)}
                                                    onToggle={() => {
                                                        const values = formik.values.scopes.some(
                                                            (_scope) => _scope === ApiAccessScope.COLLECTIONS_CARD
                                                        )
                                                            ? formik.values.scopes.filter((_scope) => _scope !== ApiAccessScope.COLLECTIONS_CARD)
                                                            : [...formik.values.scopes, ApiAccessScope.COLLECTIONS_CARD];
                                                        formik.getFieldHelpers("scopes").setValue([...values]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <h5 className="text-lg font-medium text-black-secondary">Disbursement</h5>
                                        <div className="space-y-6">
                                            <div className="flex items-center justify-between">
                                                <p className="text-base text-black-secondary">Bank Account</p>
                                                <ToggleSwitch
                                                    isActive={formik.values.scopes.some(
                                                        (_scope) => _scope === ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT
                                                    )}
                                                    onToggle={() => {
                                                        const values = formik.values.scopes.some(
                                                            (_scope) => _scope === ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT
                                                        )
                                                            ? formik.values.scopes.filter(
                                                                  (_scope) => _scope !== ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT
                                                              )
                                                            : [...formik.values.scopes, ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT];
                                                        formik.getFieldHelpers("scopes").setValue([...values]);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <p className="text-base text-black-secondary">Mobile Money</p>
                                                <ToggleSwitch
                                                    isActive={formik.values.scopes.some(
                                                        (_scope) => _scope === ApiAccessScope.DISBURSEMENT_MOBILE_MONEY
                                                    )}
                                                    onToggle={() => {
                                                        const values = formik.values.scopes.some(
                                                            (_scope) => _scope === ApiAccessScope.DISBURSEMENT_MOBILE_MONEY
                                                        )
                                                            ? formik.values.scopes.filter(
                                                                  (_scope) => _scope !== ApiAccessScope.DISBURSEMENT_MOBILE_MONEY
                                                              )
                                                            : [...formik.values.scopes, ApiAccessScope.DISBURSEMENT_MOBILE_MONEY];
                                                        formik.getFieldHelpers("scopes").setValue([...values]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-12 flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-10">
                                    <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                        <span className="w-full text-center text-black-tertiary">Cancel</span>
                                    </ButtonComp>

                                    <ButtonComp fullWidth type="submit" size="lg" buttonType="primary" color={"black"}>
                                        <span className="w-full text-center">Save Changes</span>
                                    </ButtonComp>
                                </div>
                            </FullPageTrayBody>
                        </Form>
                    );
                }}
            </Formik>
        </FullPageTray>
    );
}

export default DefaultSetupChannelsTray;
