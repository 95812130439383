import React, { useCallback, useEffect, useRef, useState } from "react";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import CardMain from "../../../../../../models/cardMain";
import { IRootState } from "../../../../../../redux/rootReducer";
import ToggleSwitch from "../../../../../../components/general/ToggleSwitch/ToggleSwitch";
import useCards from "../../../Hooks/State/useCards";
import { useSelector } from "react-redux";

interface ISetPaymentChannel {
    cardData: CardMain;
    isSelectedCardDetailsOpen: boolean;
    handleBack: () => void;
}

function SetSecurity({ handleBack }: ISetPaymentChannel): JSX.Element {
    const showCVV = useSelector((state: IRootState) => state.cards.showCVV);

    const { isCardShowCvvLoading, handleCardShowCVV } = useCards();
    const [showCVVState, setShowCVVState] = useState(false);

    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        divRef.current?.scrollIntoView();
    }, []);

    useEffect(() => {
        setShowCVVState(showCVV);
    }, [showCVV]);

    const handleRequest = useCallback(async () => {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await handleCardShowCVV({ showCvv: showCVVState });
        handleBack();
    }, [showCVVState, showCVV, handleBack]);

    return (
        <div className="mt-6 w-full space-y-4 pb-6" data-type="card-active" ref={divRef}>
            <div className="flex flex-col py-4" data-type="card-active">
                <h3 className="text-base font-medium leading-none text-black" data-type="card-active">
                    Security
                </h3>
                <p className="pt-1 text-sm font-normal leading-none text-black-tertiary" data-type="card-active">
                    Manage your card&apos;s security
                </p>
            </div>
            <div className="w-full" data-type="card-active">
                <div className="flex w-full items-center justify-between pb-4" data-type="card-active">
                    <div className="space-y-1" data-type="card-active">
                        <p className="text-sm font-medium text-black-secondary" data-type="card-active">
                            Card Verification Value (CVV)
                        </p>
                        <p className="text-xs font-normal text-black-tertiary" data-type="card-active">
                            Show card CVV on card details
                        </p>
                    </div>
                    <ToggleSwitch
                        isActive={showCVVState}
                        onToggle={() => {
                            setShowCVVState((prev) => !prev);
                        }}
                    />
                </div>
            </div>

            <div className="flex w-full flex-row items-center justify-center space-x-4 pt-4" data-type="card-active">
                <ButtonComp size="lg" buttonType="secondary" color="grey" onClick={handleBack} dataType="card-active">
                    Cancel
                </ButtonComp>
                <ButtonComp
                    size="lg"
                    color="black"
                    dataType="card-active"
                    buttonType="primary"
                    disable={showCVV === showCVVState}
                    isLoading={isCardShowCvvLoading}
                    onClick={handleRequest}
                >
                    Save Changes
                </ButtonComp>
            </div>
        </div>
    );
}
export default SetSecurity;
