import ButtonComp from "../../../../../../components/button/ButtonComp";
import { IRootState } from "../../../../../../redux/rootReducer";
import Modal from "../../../../../../components/modal/Modal";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../../components/modal/modal-header";
import Otp from "../../../../../../components/otp";
import { useSelector } from "react-redux";

interface Props {
    error: string | null;
    value: string;
    active: boolean;
    isLoading: boolean;
    counterMinutes: number;
    counterSeconds: number;
    otpFunc: (otp: string) => void;
    toggler: () => void;
    resendFunc: () => void;
    handleSubmit: () => void;
}

function ChangePasswordOtp({ otpFunc, resendFunc, error, value, isLoading, active, toggler, handleSubmit }: Props): JSX.Element {
    const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

    return (
        <>
            <Modal size="xs" active={active} toggler={toggler}>
                <ModalHeader onClose={toggler}>Change password</ModalHeader>
                <ModalBody>
                    <div className="relative flex w-full flex-col space-y-4">
                        <p className="text-sm font-normal text-black-secondary">
                            We sent a one-time password to your {isAuthAppSetup ? "Token App" : "email"} to change your password
                        </p>
                        <Otp value={value || undefined} onChange={otpFunc} isError={error} handleResend={resendFunc} canResendOtp />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ButtonComp
                        type="button"
                        ripple="light"
                        buttonType="secondary"
                        color="grey"
                        func={() => {
                            toggler();
                        }}
                    >
                        <span>Back</span>
                    </ButtonComp>

                    <ButtonComp type="submit" color="blue" ripple="light" buttonType="primary" isLoading={isLoading} onClick={handleSubmit}>
                        <span>Authorize</span>
                    </ButtonComp>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ChangePasswordOtp;
