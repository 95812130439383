import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import { Link, useParams } from "react-router-dom";
import { LoginForm, LoginInviteParams, LoginStages } from "../hooks/login.constant";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import LencoFormInput from "../../../../components/inputs/FormInput";
import LencoSpinner from "../../../../components/spinner";
import Otp from "../../../../components/otp";
import QRCode from "../../../../assets/images/login/login-qr-code.svg";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import useLogin from "../hooks/use-login";
import useLoginInvite from "../hooks/use-login-invite";
import useLoginInviteOtp from "../hooks/use-login-invite-otp";
import useLoginOtp from "../hooks/use-login-otp";
import useVerifyLoginInvite from "../hooks/use-verify-login-invite";

function Login(): JSX.Element {
    const { a, b, type } = useParams<LoginInviteParams>();

    const [invite, setInvite] = useState<boolean>(false);
    const [section, setSection] = useState<LoginStages>(LoginStages.FORM);
    const [loginOtp, setLoginOtp] = useState<string>("");
    const [userDetails, setUserDetails] = useState<LoginForm | null>(null);
    const [isAuthAppSetup, setIsAuthAppSetup] = useState<boolean>(false);

    const formikRef = useRef<FormikProps<LoginForm> | null>(null);

    const {
        error: loginError,
        mutate: loginMutate,
        isPending: isLoginPending,
    } = useLogin({
        onComplete: (_isAuthAppSetup) => {
            setSection(LoginStages.OTP);
            setIsAuthAppSetup(_isAuthAppSetup);
        },
    });

    const {
        error: loginInviteError,
        mutate: loginInviteMutate,
        isPending: isLoginInvitePending,
    } = useLoginInvite({
        onComplete: (_isAuthAppSetup) => {
            setSection(LoginStages.OTP);
            setIsAuthAppSetup(_isAuthAppSetup);
        },
    });

    const {
        data: verifyLoginInviteData,
        error: verifiedLoginInviteError,
        mutate: verifyLoginInviteMutate,
        isPending: isVerifiedLoginInvitePending,
    } = useVerifyLoginInvite();

    const { error: loginOtpError, mutate: loginOtpMutate, isPending: isLoginOtpPending, reset } = useLoginOtp();

    const {
        error: loginInviteOtpError,
        mutate: loginInviteOtpMutate,
        isPending: isLoginInviteOtpPending,
        reset: resetLoginOtp,
    } = useLoginInviteOtp();

    const INITIAL_FORM_STATE = {
        username: verifyLoginInviteData?.email || "",
        password: "",
    };
    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().required(),
        password: Yup.string().required(),
    });

    useLayoutEffect(() => {
        if (!a || !b || !type) return setInvite(false);
        setInvite(true);
        verifyLoginInviteMutate({ a, b, type, auth: "login" });
    }, [a, b, type]);

    useEffect(() => {
        if (!loginOtp || loginOtp.length < 6 || !userDetails) return;
        if (invite && a && b && type) {
            loginInviteOtpMutate({ username: userDetails.username || "", password: userDetails.password || "", a, b, type, otp: loginOtp });
            return;
        }
        loginOtpMutate({ username: userDetails.username || "", password: userDetails.password || "", otp: loginOtp });
    }, [a, b, type, userDetails, invite, loginOtp]);

    //resend OTP
    const handleResend = () => {
        if (!userDetails) return;
        if (invite && a && b && type) {
            loginInviteMutate({ username: userDetails.username || "", password: userDetails.password || "", a, b, type });
        } else {
            loginMutate({ username: userDetails.username || "", password: userDetails.password || "" });
        }
    };

    return (
        <div className="flex w-full flex-col items-center justify-center">
            {invite && isVerifiedLoginInvitePending && <LencoSpinner size="lg" />}
            {(verifiedLoginInviteError || loginError || loginInviteError) && !isVerifiedLoginInvitePending && (
                <div className="flex w-full max-w-md items-center justify-center pb-4">
                    <ErrorToast error={verifiedLoginInviteError || loginError || loginInviteError} />
                </div>
            )}
            {!verifiedLoginInviteError && !isVerifiedLoginInvitePending && (
                <div className="flex h-full w-full flex-col items-center justify-center space-y-6">
                    {section === LoginStages.FORM && (
                        <>
                            <div className="relative flex w-full max-w-md flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                                {invite ? (
                                    <h2 className="text-center font-medium text-black">
                                        You have been invited to join
                                        <span className="font-bold"> {verifyLoginInviteData?.businessName} </span>
                                        on Lenco
                                    </h2>
                                ) : (
                                    <h2 className="text-2xl font-medium text-black">Login to your account</h2>
                                )}

                                <Formik
                                    initialValues={{
                                        ...INITIAL_FORM_STATE,
                                    }}
                                    innerRef={formikRef}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={(values) => {
                                        invite && a && b && type ? loginInviteMutate({ ...values, a, b, type }) : loginMutate(values);
                                        setUserDetails(values);
                                    }}
                                    enableReinitialize
                                    validateOnChange
                                    validateOnMount
                                >
                                    {(formik) => {
                                        return (
                                            <Form className="flex w-full flex-col items-center justify-center space-y-8" tabIndex={-1}>
                                                <div className="flex w-full flex-col space-y-4">
                                                    <LencoFormInput label="Username or Email Address" name="username" />
                                                    <div className="flex w-full flex-col items-start justify-start space-y-2">
                                                        <LencoFormInput label="Password" name="password" type="password" />
                                                        <Link to="/forgot-password" className="w-full">
                                                            <ButtonComp size="md" color="black" buttonType="tertiary" isText>
                                                                Forgot password
                                                            </ButtonComp>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <ButtonComp
                                                    type="submit"
                                                    color="black"
                                                    buttonType="primary"
                                                    disable={!formik.isValid}
                                                    isLoading={isLoginPending || isLoginInvitePending}
                                                    fullWidth
                                                    isText
                                                >
                                                    Log In
                                                </ButtonComp>

                                                <Link to="/signup">
                                                    <ButtonComp size="md" color="black" buttonType="tertiary" isText fullWidth>
                                                        Create an Account
                                                    </ButtonComp>
                                                </Link>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                            <p className="text-sm text-black-secondary">OR</p>
                            <div className="relative flex w-full max-w-md items-center justify-center space-x-3 rounded-lg bg-white p-4 3xs:p-8">
                                <div className="w-20">
                                    <img className="h-full w-full" src={QRCode} alt="login qr code" />
                                </div>
                                <div className="flex w-full flex-col">
                                    <h3 className="text-lg font-bold text-black">Login with QR code</h3>
                                    <p className="text-xs text-black-secondary">
                                        Scan this phone with your phone to login to your Lenco account instantly
                                    </p>
                                </div>
                            </div>
                        </>
                    )}

                    {section === LoginStages.OTP && (
                        <div className="relative flex w-full max-w-md flex-col items-center justify-center space-y-8 rounded-lg bg-white p-4 3xs:p-8">
                            <div className="flex w-full max-w-[360px] flex-col items-center justify-start space-y-4">
                                <h3 className="text-center text-2xl font-medium text-black">Enter OTP</h3>
                                <p className="text-center font-normal text-black-secondary">
                                    {isAuthAppSetup
                                        ? "Enter the OTP generated from your token app"
                                        : "We sent a one-time password to your email to authenticate your login."}
                                </p>
                            </div>
                            <Otp
                                value={loginOtp}
                                isError={loginOtpError || loginInviteOtpError ? getErrorMessage(loginOtpError || loginInviteOtpError) : null}
                                onChange={(_value) => setLoginOtp(_value)}
                                handleResend={handleResend}
                                resendMins={3}
                                isResendOtpLoading={isLoginPending || isLoginInvitePending}
                                active={section === LoginStages.OTP}
                                canResendOtp={!isAuthAppSetup}
                            />
                            <div className="flex w-full flex-row items-center justify-center space-x-4">
                                <ButtonComp
                                    size="xl"
                                    color="grey"
                                    buttonType="secondary"
                                    onClick={() => {
                                        setLoginOtp("");
                                        reset();
                                        resetLoginOtp();
                                        setUserDetails(null);
                                        setIsAuthAppSetup(false);
                                        setSection(LoginStages.FORM);
                                    }}
                                >
                                    Back
                                </ButtonComp>
                                <ButtonComp
                                    size="xl"
                                    color="black"
                                    buttonType="primary"
                                    isLoading={isLoginOtpPending || isLoginInviteOtpPending}
                                    disable
                                >
                                    Authorize
                                </ButtonComp>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default Login;
