import React, { useEffect } from "react";

import { ReactComponent as Arrow } from "../../assets/svg/arrowDownIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/Cancel.svg";
import Skeleton from "../skeleton";

interface SubTextMeta {
    text: string;
    onClose?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

interface ButtonMeta {
    onClick: () => void;
    text: string;
    isActive?: boolean;
    subTab?: SubTextMeta;
    showSubTab?: boolean;
}

interface Props {
    buttons: ButtonMeta[];
    isLoading?: boolean;
}

export default function TabButton({ buttons, isLoading }: Props) {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <div className="flex h-fit w-full flex-col sm:flex-row sm:items-center sm:justify-between">
            <div className="flex w-full items-center justify-start space-x-3">
                {!isLoading &&
                    buttons?.map(({ text, onClick, subTab, showSubTab, isActive }, idx, buttons_arr) => {
                        return (
                            <>
                                {!buttons_arr?.filter((_, idy) => idy != idx).find((bMeta) => bMeta.showSubTab) && (
                                    <>
                                        <button
                                            key={idx}
                                            className={
                                                "my-1 flex h-8 items-center justify-center rounded-lg px-4 py-2 text-sm transition-colors duration-300" +
                                                (subTab && showSubTab && isActive
                                                    ? ` bg-white text-black-tertiary`
                                                    : ` ${
                                                          isActive
                                                              ? " bg-white text-black"
                                                              : " border-transparent text-black-tertiary hover:border-gray-200"
                                                      }`)
                                            }
                                            onClick={() => {
                                                onClick();
                                            }}
                                        >
                                            {text}
                                        </button>

                                        {subTab && showSubTab && isActive && (
                                            <div className="flex h-full items-center justify-start space-x-3">
                                                <div className="flex h-7 w-7 items-center justify-center rounded-full pl-0.5 text-current transition-colors duration-150 ease-in-out">
                                                    <Arrow className="w-3.5 origin-center -rotate-90 transform stroke-current" />
                                                </div>
                                                <div className="flex h-8 w-max cursor-pointer items-center justify-start rounded-lg bg-white px-3 py-1.5 text-xs font-medium leading-none text-black-secondary">
                                                    <p className="pointer-events-none select-none">{subTab.text || ""} </p>
                                                    <span
                                                        className="ml-4 cursor-pointer text-xs text-black-tertiary hover:text-blue"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            subTab?.onClose?.(e);
                                                        }}
                                                    >
                                                        <CancelIcon className="stroke-current" />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        );
                    })}
                {isLoading && (
                    <div className="my-3 flex items-center space-x-14">
                        <div className="h-8 w-28 rounded-lg">
                            <Skeleton box />
                        </div>
                        {buttons.find((butt) => butt.isActive && butt.showSubTab) && (
                            <div className="h-8 w-40 rounded-lg">
                                <Skeleton box />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
