import { CardAccessForm, CardUsageStage } from "../../../Types";
import React, { useEffect } from "react";
import { updateUseExistingBusinessAddress, updateUseExistingMobileNumber } from "../../../../../../redux/cards/slice/cardsSlice";

import ButtonComp from "../../../../../../components/button/ButtonComp";
import Checkbox from "../../../../../../components/checkbox";
import FormInput from "../../../../../../components/inputs/FormInput";
import { FormikProps } from "formik";
import ModalBody from "../../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../../components/modal/modal-footer";
import { isValidNigerianPhoneNumber } from "../../../../../../utils/isNigerianPhoneNumber";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";

interface ICardDeliveryProps {
    isLoading: boolean;
    formikRef: FormikProps<CardAccessForm> | null;
    onNext: (stage: CardUsageStage) => void;
}

function CardsDeliveryDetails({ isLoading, onNext, formikRef }: ICardDeliveryProps): JSX.Element {
    const dispatch = useDispatch();
    const usersList = useAppSelector((state) => state.cards.users);
    const operatingAddress = useAppSelector((state) => state.init.main?.companyDetails.company.operatingAddress);
    const useExistingBusinessAddress = useAppSelector((state) => !!state.cards.useExistingBusinessAddress);
    const useExistingMobileNumber = useAppSelector((state) => !!state.cards.useExistingMobileNumber);

    useEffect(() => {
        if (useExistingBusinessAddress) {
            formikRef?.getFieldHelpers("deliveryAddress").setValue(operatingAddress);
        }
        if (useExistingMobileNumber) {
            const holder = usersList?.find((user) => user.id === formikRef?.values?.assignedToId);
            formikRef?.getFieldHelpers("deliveryPhone").setValue(holder?.phones?.[0]);
        }
        return () => {};
    }, [useExistingBusinessAddress, useExistingMobileNumber]);

    return (
        <>
            <ModalBody>
                <div className="space-y-6">
                    <div className="space-y-4">
                        {operatingAddress && operatingAddress.length > 1 && (
                            <div
                                className="flex cursor-pointer select-none items-center gap-x-2"
                                onClick={() => dispatch(updateUseExistingBusinessAddress(!useExistingBusinessAddress))}
                            >
                                <Checkbox size="md" id={"existingCardHolderNumber"} checked={useExistingBusinessAddress} />
                                <p className="text-sm font-medium text-black-secondary">Same as Operating Business Address</p>
                            </div>
                        )}
                        <FormInput type="textarea" placeholder="Delivery Address" name="deliveryAddress" isDisabled={useExistingBusinessAddress} />
                    </div>
                    <div className="space-y-4">
                        <div
                            className="flex cursor-pointer select-none items-center gap-x-2"
                            onClick={() => dispatch(updateUseExistingMobileNumber(!useExistingMobileNumber))}
                        >
                            <Checkbox size="md" id={"existingCardHolderNumber"} checked={useExistingMobileNumber} />
                            <p className="text-sm font-medium text-black-secondary">Use existing mobile number</p>
                        </div>
                        <FormInput type="string" placeholder="Delivery Phone Number" name="deliveryPhone" isDisabled={useExistingMobileNumber} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                    <ButtonComp color="grey" size="lg" buttonType="secondary" onClick={() => onNext(CardUsageStage.HOLDER)}>
                        Back
                    </ButtonComp>
                </div>
                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                    <ButtonComp
                        size="lg"
                        type="submit"
                        color="black"
                        buttonType="primary"
                        isLoading={isLoading}
                        disable={
                            !formikRef?.values.deliveryAddress ||
                            !formikRef?.values.deliveryPhone ||
                            !isValidNigerianPhoneNumber(formikRef?.values.deliveryPhone || "")
                        }
                        onClick={() => onNext(CardUsageStage.DELIVERY_DETAILS)}
                    >
                        Request Card
                    </ButtonComp>
                </div>
            </ModalFooter>
        </>
    );
}
// !isValidNigerianPhoneNumber(cardholderDetailsNumber) || !!formik.errors.deliveryPhone || !!formik.errors.deliveryAddress

export default CardsDeliveryDetails;
