import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import {
    LencoTerminalAccessForm,
    LencoTerminalAccessLevel,
    LencoTerminalAccessLevelText,
    LencoTerminalAccessLevels,
    LencoTerminalAccessStage,
} from "../../Types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { IRootState } from "../../../../../redux/rootReducer";
import { Link } from "react-router-dom";
import MessageToast from "../../../../../components/message-toast";
import Modal from "../../../../../components/modal/Modal";
import ModalBody from "../../../../../components/modal/modal-body";
import ModalFooter from "../../../../../components/modal/modal-footer";
import ModalHeader from "../../../../../components/modal/modal-header";
import MultiSelectDropdown from "../../../../../components/dropdown/multi-select-dropdown";
import SingleSelectDropdown from "../../../../../components/dropdown/single-select/single-select";
import TeamAccessAdminButton from "../TeamAccessAdminButton";
import User from "../../../../../models/user";
import { messageTrue } from "../../../../../redux/app-toast/app-toast-slice";
import { useAppSelector } from "../../../../../redux/hooks";
import useTerminals from "../../Hooks/State/useTerminals";

interface Props {
    show: boolean;
    toggle: () => void;
}

function removeDuplicateIds(list: User[]) {
    const uniqueIds = new Set();
    const filteredList: User[] = [];

    list?.forEach((item) => {
        if (!uniqueIds.has(item?.id)) {
            uniqueIds.add(item?.id);
            filteredList.push(item);
        }
    });

    return filteredList;
}

export default function TerminalTeamAccessModal({ show, toggle }: Props) {
    const dispatch = useDispatch();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<User | null>(null);
    const [teamMembersWithAccess, setTeamMembersWithAccess] = useState<User[]>([]);
    const {
        handleAddManagerAccess,
        handleAddViewerAccess,
        isAddViewerAccessLoading,
        isAddManagerAccessLoading,
        handleRemoveManageAccess,
        handleRemoveViewerAccess,
        isRemoveManageAccessLoading,
        isRemoveViewerAccessLoading,
    } = useTerminals();
    const [stage, setStage] = useState<LencoTerminalAccessStage>(LencoTerminalAccessStage.LIST_OF_TEAM_MEMBERS);

    const currentUser = useSelector((state: IRootState) => state?.init.main?.companyDetails.user);
    const formikRef = useRef<FormikProps<LencoTerminalAccessForm> | null>(null);

    const InitialLencoPayAccessState: LencoTerminalAccessForm = {
        memberIds: [],
        accessLevel: null,
    };

    const teamMembers = useAppSelector((state) => state.terminals.teamMembers) || [];
    const { teamMembersWithManageAccess, teamMembersWithViewAccess } = useSelector((state: IRootState) => state?.terminals.settings);

    const LencoPayAccessFormValidation = Yup.object().shape({
        memberIds: Yup.array().of(Yup.string().required("Required")).required("Required"),
        accessLevel: Yup.mixed<LencoTerminalAccessLevel>().oneOf(Object.values(LencoTerminalAccessLevel)).required(),
    });

    const handleMemberAccess = useCallback(async (values: { memberIds: string[]; accessLevel: LencoTerminalAccessLevel | null }) => {
        if (!currentUser?.hasManageTerminalAccess) {
            dispatch(messageTrue({ message: "You don't have permission to add User Access" }));
            return handleBack?.();
        }
        if (values.accessLevel === LencoTerminalAccessLevel.MANAGER) {
            await handleAddManagerAccess({ memberIds: values.memberIds });
            return handleBack?.();
        }
        if (values.accessLevel === LencoTerminalAccessLevel.VIEWER) {
            await handleAddViewerAccess({ memberIds: values.memberIds });
            return handleBack?.();
        }
    }, []);

    const handleRemoveMemberAccess = useCallback(async (user: User): Promise<void> => {
        if (!currentUser?.hasManageTerminalAccess) {
            dispatch(messageTrue({ message: "You don't have permission to remove User Access" }));
            return handleBack?.();
        }
        if (user.hasManageTerminalAccess) {
            await handleRemoveManageAccess({ memberId: user.id });
            await handleRemoveViewerAccess({ memberId: user.id });
            return handleBack?.();
        } else {
            await handleRemoveViewerAccess({ memberId: user.id });
            return handleBack?.();
        }
    }, []);

    const onSelectAddNewMember = useCallback(() => {
        setStage(LencoTerminalAccessStage.CREATE_ACCESS);
    }, []);

    const handleBack = useCallback(() => {
        setStage(LencoTerminalAccessStage.LIST_OF_TEAM_MEMBERS);
    }, []);

    const initTeamMemberWithAccess = useCallback(() => {
        if (!removeDuplicateIds(teamMembersWithManageAccess)?.length && !removeDuplicateIds(teamMembersWithViewAccess)?.length)
            return setTeamMembersWithAccess([currentUser as User]?.filter((_) => !!_));
        setTeamMembersWithAccess(removeDuplicateIds([...teamMembersWithManageAccess, ...teamMembersWithViewAccess]));
    }, [teamMembersWithManageAccess, teamMembersWithViewAccess, currentUser]);

    useEffect(() => {
        initTeamMemberWithAccess();
        return () => {};
    }, [teamMembersWithManageAccess, teamMembersWithViewAccess, currentUser]);

    useEffect(() => {
        if (show) {
            setStage(LencoTerminalAccessStage.LIST_OF_TEAM_MEMBERS);
            formikRef?.current?.resetForm();
        }
        return () => {};
    }, [show]);

    return (
        <>
            <ConfirmDeleteModal
                isActive={!!showConfirmDeleteModal}
                isLoading={isRemoveViewerAccessLoading || isRemoveManageAccessLoading}
                onDelete={async () => {
                    showConfirmDeleteModal && (await handleRemoveMemberAccess(showConfirmDeleteModal));
                    setShowConfirmDeleteModal(null);
                }}
                title="Remove Access"
                subText={<span>Are you sure you want to remove {showConfirmDeleteModal?.name}&apos;s access? </span>}
                toggler={() => setShowConfirmDeleteModal(null)}
            />

            <Modal size="md" active={show && !showConfirmDeleteModal} toggler={toggle}>
                <ModalHeader subTitle="Add who can manage and view your Terminals" onClose={toggle}>
                    Terminal Access
                </ModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ ...InitialLencoPayAccessState }}
                    validationSchema={LencoPayAccessFormValidation}
                    onSubmit={(values) => {
                        void handleMemberAccess(values);
                    }}
                    enableReinitialize
                    validateOnMount
                >
                    {(formik) => {
                        return (
                            <Form className="w-full">
                                <ModalBody>
                                    {stage === LencoTerminalAccessStage.LIST_OF_TEAM_MEMBERS && (
                                        <div className="flex w-full flex-col space-y-3">
                                            {teamMembersWithAccess?.map((teamMember, idx) => (
                                                <TeamAccessAdminButton
                                                    key={idx}
                                                    name={teamMember.name}
                                                    canManage={
                                                        teamMember.id !== currentUser?.id &&
                                                        !teamMember.isAdmin &&
                                                        currentUser?.hasManageTerminalAccess
                                                    }
                                                    isManager={teamMember.hasManageTerminalAccess}
                                                    isAdmin={teamMember.isAdmin}
                                                    onDelete={() => {
                                                        setShowConfirmDeleteModal(teamMember);
                                                    }}
                                                />
                                            ))}

                                            {!teamMembers?.filter?.((_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id))
                                                ?.length && (
                                                <div className="">
                                                    <MessageToast
                                                        message={
                                                            <span>
                                                                Add more users to your team under{" "}
                                                                <Link className="font-medium underline" to={"/settings/team-members"}>
                                                                    Settings
                                                                </Link>
                                                            </span>
                                                        }
                                                        className="mx-auto w-max lg:w-full"
                                                        type="info"
                                                        fullWidth={true}
                                                        size="lg"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {stage === LencoTerminalAccessStage.CREATE_ACCESS && (
                                        <div className="w-full space-y-3">
                                            <MultiSelectDropdown
                                                placeholder="Select Team Member"
                                                value={formik.values.memberIds}
                                                options={teamMembers
                                                    .filter((_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id))
                                                    .map((el) => {
                                                        return {
                                                            text: el.name,
                                                            value: el.id,
                                                        };
                                                    })}
                                                onChange={(value) => {
                                                    void formik?.setFieldValue?.("memberIds", value);
                                                }}
                                                isDisabled={
                                                    !!(
                                                        teamMembers.filter(
                                                            (_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id)
                                                        ).length < 1
                                                    )
                                                }
                                                active={
                                                    !!teamMembers.filter((_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id))
                                                        ?.length
                                                }
                                            />
                                            <SingleSelectDropdown
                                                placeholder="Access level"
                                                value={formik.values.accessLevel}
                                                options={LencoTerminalAccessLevels.map((_) => ({
                                                    text: LencoTerminalAccessLevelText[_],
                                                    value: _,
                                                }))}
                                                onChange={(value) => void formik.getFieldHelpers("accessLevel").setValue(value || null)}
                                                bigDropdown
                                                size="lg"
                                            />
                                        </div>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <div className="mt-4 flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
                                        {stage === LencoTerminalAccessStage.LIST_OF_TEAM_MEMBERS && (
                                            <>
                                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                    <ButtonComp
                                                        onClick={onSelectAddNewMember}
                                                        size="lg"
                                                        color="grey"
                                                        buttonType="secondary"
                                                        disable={
                                                            isRemoveManageAccessLoading ||
                                                            isRemoveViewerAccessLoading ||
                                                            !teamMembers?.filter?.(
                                                                (_user) => !teamMembersWithAccess.some((_access) => _access.id === _user.id)
                                                            )?.length
                                                        }
                                                    >
                                                        Add Another
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                    <ButtonComp
                                                        size="lg"
                                                        color="black"
                                                        buttonType="primary"
                                                        isLoading={isRemoveManageAccessLoading || isRemoveViewerAccessLoading}
                                                        onClick={toggle}
                                                    >
                                                        Done
                                                    </ButtonComp>
                                                </div>
                                            </>
                                        )}
                                        {stage === LencoTerminalAccessStage.CREATE_ACCESS && (
                                            <>
                                                <div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
                                                    <ButtonComp size="lg" color="grey" buttonType="secondary" onClick={handleBack}>
                                                        Back
                                                    </ButtonComp>
                                                </div>
                                                <div className="order-1 w-full 2xs:order-2 2xs:w-max">
                                                    <ButtonComp
                                                        size="lg"
                                                        type="submit"
                                                        color="black"
                                                        buttonType="primary"
                                                        isLoading={isAddViewerAccessLoading || isAddManagerAccessLoading}
                                                        disable={
                                                            !formik.values.memberIds.length || isAddViewerAccessLoading || isAddManagerAccessLoading
                                                        }
                                                    >
                                                        Grant Access
                                                    </ButtonComp>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
}
