import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComp from "../../../../../../../../components/button/ButtonComp";
import ChannelsTray from "./Components/Trays/ChannelsTray";
import CollectionFeesTray from "./Components/Trays/CollectionFeesTray";
import { IRootState } from "../../../../../../../../redux/rootReducer";
import NewUserAccountDropdown from "../../../../../../../../components/user-account-dropdown";
import OtherAccountsTray from "./Components/Trays/OtherAccountsTray";
import { Routes } from "../../../../../../../../routes/routes.constants";
import { updateSettlementAccountIdDefaultSetupConfiguration } from "../../../../../../../../redux/zambia/defaultSetup/zambiaDefaultSetupSlice";
import useLencoPayDefaultSetupInit from "../../../../../hookss/DefaultSetup/useLencoPayDefaultSetupInit";
import { useNavigate } from "react-router-dom";
import useUpdateLencoPayDefaultSetup from "../../../../../hookss/DefaultSetup/useUpdateLencoPayDefaultSetup";

// import DefaultSetupCollectionFeesTray from "../../../../DefaultSetup/Trays/CollectionFeesTray";

function Setup(): JSX.Element {
    const dispatch = useDispatch();
    // const { handleUpdateZambiaApiAccessKeySettlementAccount } = useZambiaSetup();
    const selectedApiAccessKey = useSelector((state: IRootState) => state.zambiaApiAccessKeys.selectedApiAccessKey);

    const navigate = useNavigate();

    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
    const tempDefaultSetupConfiguration = useSelector((state: IRootState) => state.zambiaDefaultSetup.tempDefaultSetupConfiguration);

    const [isChannelTrayOpen, setIsChannelTrayOpen] = useState(false);
    const [isCollectionFeeTrayOpen, setIsCollectionFeeTrayOpen] = useState(false);
    const [isOtherAccountsTrayOpen, setIsOtherAccountsTrayOpen] = useState(false);

    const isLencoPayEnabled = useSelector((state: IRootState) => state.init.main?.meta.lencoPay.isEnabled);
    const { handleLencoPayDefaultSetupInit } = useLencoPayDefaultSetupInit();
    const { isUpdateLencoPayDefaultSetupLoading, handleUpdateLencoPayDefaultSetup } = useUpdateLencoPayDefaultSetup();

    useEffect(() => {
        void handleLencoPayDefaultSetupInit();
    }, []);

    return (
        <>
            <OtherAccountsTray
                selectedApiAccessKey={selectedApiAccessKey}
                active={isOtherAccountsTrayOpen}
                toggler={() => setIsOtherAccountsTrayOpen(false)}
            />
            <CollectionFeesTray
                active={isCollectionFeeTrayOpen}
                selectedApiAccessKey={selectedApiAccessKey}
                toggler={() => setIsCollectionFeeTrayOpen(false)}
            />

            <ChannelsTray active={isChannelTrayOpen} selectedApiAccessKey={selectedApiAccessKey} toggler={() => setIsChannelTrayOpen(false)} />

            {selectedApiAccessKey && (
                <>
                    <div className="flex w-full items-start justify-start space-x-8 pt-6">
                        <div className="w-full max-w-2xl space-y-8 pb-8">
                            <div className="flex w-full flex-col space-y-6 rounded-lg bg-white px-6 py-4">
                                <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                    <div className="w-full">
                                        <h5 className="text-base font-medium capitalize text-black">Settlement Type</h5>
                                    </div>
                                    <div className="w-full max-w-15 space-y-0.5 rounded-md bg-grey-backdrop p-2 px-3">
                                        <div className="grid grid-cols-2">
                                            <p className="text-xs text-black-secondary">Mobile Money</p>
                                            <p className="text-right text-xs font-medium">Instant</p>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <p className="text-xs text-black-secondary">Cards</p>
                                            <p className="text-right text-xs font-medium">
                                                T+1 <span className="font-normal">(Next Day)</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex w-full flex-col space-y-4 rounded-lg bg-white px-6 py-4">
                                <div className="flex w-full flex-col items-center justify-between xl:flex-row">
                                    <div className="flex w-full flex-col space-y-0.5">
                                        <h5 className="text-base font-medium capitalize text-black">Settlement account</h5>
                                        <p className="text-sm font-normal text-black-tertiary">Choose an account for settlement</p>
                                    </div>
                                    <div className="relative flex w-full max-w-15 justify-end">
                                        <NewUserAccountDropdown
                                            value={
                                                !isLencoPayEnabled &&
                                                accounts &&
                                                accounts?.length < 2 &&
                                                !tempDefaultSetupConfiguration.settlementAccountId
                                                    ? accounts[0].id
                                                    : tempDefaultSetupConfiguration.settlementAccountId || ""
                                            }
                                            options={accounts || null}
                                            onClick={(_value) => dispatch(updateSettlementAccountIdDefaultSetupConfiguration(_value))}
                                            size="md"
                                            initiatorCanSelect
                                            isHideInitials
                                        />
                                    </div>
                                </div>

                                <div className="w-full border-b border-grey-secondary"></div>

                                <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                    <div className="flex w-full flex-col space-y-0.5">
                                        <h5 className="text-base font-medium capitalize text-black">Collection Fees</h5>
                                        <p className="text-sm font-normal text-black-tertiary">Select who pays the cost of collections</p>
                                    </div>
                                    <ButtonComp
                                        color="blue"
                                        type="button"
                                        buttonType="tertiary"
                                        size="md"
                                        func={() => setIsCollectionFeeTrayOpen(true)}
                                    >
                                        <span className="font-medium">Manage</span>
                                    </ButtonComp>
                                </div>
                                <div className="w-full border-b border-grey-secondary"></div>

                                <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 2xs:grid-cols-2 2xs:justify-items-end">
                                    <div className="flex w-full flex-1 flex-col space-y-0.5">
                                        <h5 className="text-base font-medium capitalize text-black">Channels</h5>
                                        <p className="text-sm font-normal text-black-tertiary">Select what services the API can access</p>
                                    </div>
                                    <ButtonComp color="blue" type="button" buttonType="tertiary" size="md" func={() => setIsChannelTrayOpen(true)}>
                                        <span className="font-medium">Manage</span>
                                    </ButtonComp>
                                </div>

                                <div className="w-full border-b border-grey-secondary"></div>

                                <div className="grid w-full grid-cols-1 items-center justify-items-start gap-6 pb-2 2xs:grid-cols-2 2xs:justify-items-end">
                                    <div className="flex w-full flex-1 flex-col space-y-0.5">
                                        <h5 className="text-base font-medium capitalize text-black">
                                            Other Accounts <span className="font-normal">(Optional)</span>
                                        </h5>
                                        <p className="text-sm font-normal text-black-tertiary">Select other accounts that the API can access</p>
                                    </div>
                                    <ButtonComp
                                        color="blue"
                                        type="button"
                                        buttonType="tertiary"
                                        size="md"
                                        func={() => setIsOtherAccountsTrayOpen(true)}
                                    >
                                        <span className="font-medium">Manage</span>
                                    </ButtonComp>
                                </div>
                            </div>{" "}
                            <div className="flex w-full items-center justify-center pt-10">
                                <ButtonComp
                                    color="black"
                                    buttonType="primary"
                                    isLoading={isUpdateLencoPayDefaultSetupLoading}
                                    onClick={async () => {
                                        if (tempDefaultSetupConfiguration.settlementAccountId) {
                                            await handleUpdateLencoPayDefaultSetup({
                                                ipWhitelist: tempDefaultSetupConfiguration.ipWhitelist,
                                                otherAccountsAccessIds: tempDefaultSetupConfiguration.otherAccountsAccessIds,
                                                webhookUrl: tempDefaultSetupConfiguration.webhookUrl || undefined,
                                                scopes: tempDefaultSetupConfiguration.scopes,
                                                collectionFeeSource: tempDefaultSetupConfiguration.collectionFeeSource,
                                                settlementAccountId: tempDefaultSetupConfiguration.settlementAccountId,
                                            });
                                        }
                                        if (
                                            !isLencoPayEnabled &&
                                            accounts &&
                                            accounts?.length < 2 &&
                                            !tempDefaultSetupConfiguration.settlementAccountId
                                        ) {
                                            await handleUpdateLencoPayDefaultSetup({
                                                ipWhitelist: tempDefaultSetupConfiguration.ipWhitelist || [],
                                                otherAccountsAccessIds: tempDefaultSetupConfiguration.otherAccountsAccessIds || [],
                                                webhookUrl: tempDefaultSetupConfiguration.webhookUrl || undefined,
                                                scopes: tempDefaultSetupConfiguration.scopes,
                                                collectionFeeSource: tempDefaultSetupConfiguration.collectionFeeSource,
                                                settlementAccountId: accounts[0].id || "",
                                            });

                                            // route to the API table
                                            navigate(Routes.LENCO_PAY.APIS, {
                                                state: {
                                                    from: "LencoPay initialization page",
                                                },
                                            });
                                        }
                                    }}
                                >
                                    {isLencoPayEnabled ? <span className="font-medium">Save Changes</span> : null}
                                    {!isLencoPayEnabled ? <span className="font-medium">Save & Continue</span> : null}
                                </ButtonComp>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Setup;
