import * as Yup from "yup";

import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef } from "react";

import { AddZambiaApiAccessKeyAccountAccessRequest } from "../../../../../../../Services/ApiAccessKeySettings/apiAccessKeySettingsApi.types";
import ApiAccess from "../../../../../../../../../../models/zambia/lencoPay/apiAccess";
import AvatarDetails from "../../../../../../../../../../components/avatar-details";
import ButtonComp from "../../../../../../../../../../components/button/ButtonComp";
import FullPageTray from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../../../../../components/Trays/FullPageTray/FullPageTrayHeader";
import GoogleIcon from "../../../../../../../../../../components/google-icon";
import { IRootState } from "../../../../../../../../../../redux/rootReducer";
import SingleSelectDropdown from "../../../../../../../../../../components/dropdown/single-select/single-select";
import useAddZambiaApiAccessKeyAccountAccess from "../../../../../../../hookss/ApiAccessKeySettings/Setup/useAddZambiaApiAccessKeyAccountAccess";
import useRemoveZambiaApiAccessKeyAccountAccess from "../../../../../../../hookss/ApiAccessKeySettings/Setup/useRemoveZambiaApiAccessKeyAccountAccess";
import { useSelector } from "react-redux";

const DATA_TYPE = "api-access-key-other-accounts";

interface Props {
    active: boolean;
    selectedApiAccessKey: ApiAccess | null;
    toggler: () => void;
}

function OtherAccountsTray(props: Props): JSX.Element {
    const { isAddZambiaApiAccessKeyAccountAccessLoading, handleAddZambiaApiAccessKeyAccountAccess } = useAddZambiaApiAccessKeyAccountAccess({
        onComplete: () => props.toggler(),
    });

    const { isRemoveZambiaApiAccessKeyAccountAccessLoading, handleRemoveZambiaApiAccessKeyAccountAccess } = useRemoveZambiaApiAccessKeyAccountAccess({
        onComplete: () => props.toggler(),
    });

    const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

    const formikRef = useRef<FormikProps<AddZambiaApiAccessKeyAccountAccessRequest> | null>(null);

    const selectableAccounts = (accounts || [])
        .filter((_account) => !(props.selectedApiAccessKey?.accountsAccess || []).some((_accountAccess) => _accountAccess.id === _account.id))
        .map((_account) => ({
            text: _account.lencoNameMin,
            value: _account.id,
        }));

    const INITIAL_FORM_STATE: AddZambiaApiAccessKeyAccountAccessRequest = {
        id: props.selectedApiAccessKey?.id || "",
        accountId: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        id: Yup.string().required("Required"),
        accountId: Yup.string().required("Required"),
    });

    useEffect(() => {
        formikRef.current?.getFieldHelpers("accountId").setValue("");
    }, [props.active]);

    return (
        <>
            <FullPageTray active={props.active} dataType={DATA_TYPE} handleReset={props.toggler} withClickOutside>
                <FullPageTrayHeader subTitle="Select other accounts the API can access" toggler={props.toggler} dataType={DATA_TYPE}>
                    Other Accounts
                </FullPageTrayHeader>
                <FullPageTrayBody dataType={DATA_TYPE}>
                    <Formik
                        initialValues={INITIAL_FORM_STATE}
                        validationSchema={FORM_VALIDATION}
                        innerRef={formikRef}
                        onSubmit={(values) => {
                            handleAddZambiaApiAccessKeyAccountAccess(values);
                        }}
                        enableReinitialize={true}
                        validateOnChange
                        validateOnMount
                    >
                        {(formik) => {
                            return (
                                <Form className="relative flex h-full w-full flex-col items-center justify-start space-y-24">
                                    <div className="w-full space-y-6">
                                        <div className="flex w-full items-center">
                                            <SingleSelectDropdown
                                                placeholder="Select Accounts"
                                                options={selectableAccounts}
                                                isDisabled={selectableAccounts.length < 1}
                                                onChange={(_value) => {
                                                    formik.getFieldHelpers("accountId").setValue(_value);
                                                }}
                                                size="lg"
                                                // active={active}
                                            />
                                        </div>
                                        {props.selectedApiAccessKey?.accountsAccess.map((acc, _index) => (
                                            <div key={_index} className="flex w-full items-center justify-between">
                                                <div className="flex flex-1 items-center justify-start space-x-0.5">
                                                    <AvatarDetails initials="" dataType={""} title={acc?.name} size="xs" />
                                                </div>
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        isRemoveZambiaApiAccessKeyAccountAccessLoading
                                                            ? undefined
                                                            : handleRemoveZambiaApiAccessKeyAccountAccess({
                                                                  id: props.selectedApiAccessKey?.id || "",
                                                                  accountId: acc.id,
                                                              })
                                                    }
                                                >
                                                    <GoogleIcon icon="delete" size={"xs"} />
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-12 flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-5">
                                        <ButtonComp fullWidth type="button" size="lg" buttonType="secondary" onClick={props.toggler} color={"grey"}>
                                            <span className="w-full text-center text-black-tertiary">Cancel</span>
                                        </ButtonComp>
                                        <ButtonComp
                                            fullWidth
                                            type="submit"
                                            size="lg"
                                            buttonType="primary"
                                            color={"black"}
                                            isLoading={isAddZambiaApiAccessKeyAccountAccessLoading || isRemoveZambiaApiAccessKeyAccountAccessLoading}
                                            disable={!formik.isValid}
                                        >
                                            <span className="w-full text-center">Save Changes</span>
                                        </ButtonComp>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </FullPageTrayBody>
            </FullPageTray>
        </>
    );
}

export default OtherAccountsTray;
