import { AccountInitState, InitState, LencoActiveCountries, MainInitCompanyDetailsInterface, MainInitState } from "./initSlice.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import Card from "../../../models/card";
import { CompanyInterface } from "../../../helpers/types";
import TransactionCategory from "../../../models/transactionCategory";
import User from "../../../models/user";
import UserAccount from "../../../models/userAccount";
import UserApp from "../../../models/userApp";
import UserGroup from "../../../models/userGroup";

const initialState: InitState = {
    main: null,
    error: null,
    account: null,
    loading: false,
    isLoggedIn: false,
    canAccessCards: false,
    selectedCountry: null,
    mainInitLoading: false,
    isAccountCreated: false,
    selectedCompanyId: null,
    canAccessTerminals: false,
    accountInitLoading: false,
    isSwitchingBusiness: false,
    globalActiveDataType: "",
    selectedApplicationId: null,
    isAccountOpeningLoading: false,
};

export const initSlice = createSlice({
    name: "init",
    initialState,
    reducers: {
        setAccountCreated: (state: InitState, action: PayloadAction<boolean>) => {
            state.isAccountCreated = action.payload;
        },
        setSelectedCountry: (state: InitState, action: PayloadAction<LencoActiveCountries | null>) => {
            state.selectedCountry = action.payload;
        },

        initRequest: (state: InitState) => {
            state.error = null;
            state.loading = true;
            state.accountInitLoading = true;
        },

        accountInitFailure: (state: InitState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
            state.account = null;
            state.isLoggedIn = false;
            state.accountInitLoading = false;
            state.selectedCountry = null;
        },
        accountInitSuccess: (
            state: InitState,
            action: PayloadAction<{
                account: AccountInitState;
                selectedCompanyId?: string | null;
            }>
        ) => {
            state.error = null;
            state.account = action.payload.account;
            state.loading = false;
            state.isLoggedIn = true;
            state.selectedCompanyId = action.payload.selectedCompanyId || null;
            state.accountInitLoading = false;
        },

        mainInitRequest: (state: InitState) => {
            state.error = null;
            state.loading = true;
            state.mainInitLoading = true;
        },
        mainInitFailure: (state: InitState, action: PayloadAction<string>) => {
            state.main = null;
            state.error = action.payload;
            state.loading = false;
            state.mainInitLoading = false;
            state.selectedCountry = null;
        },
        mainInitSuccess: (state: InitState, action: PayloadAction<MainInitState>) => {
            state.main = action.payload;
            state.error = null;
            state.loading = false;
            state.mainInitLoading = false;
            // state.accountOpening = null;
        },

        accountOpeningInitFailure: (state: InitState, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
            state.accountInitLoading = false;
            state.selectedCountry = null;
            state.isAccountOpeningLoading = false;
        },
        accountOpeningInitRequest: (state: InitState) => {
            // console.log("request was called");
            state.error = null;
            state.loading = false;
            state.accountInitLoading = false;
            state.isAccountOpeningLoading = true;
        },
        accountOpeningInitSuccess: (state: InitState, action: PayloadAction<string>) => {
            // console.log("success was called");
            state.main = null;
            state.error = null;
            state.loading = false;
            state.accountInitLoading = false;
            state.isAccountOpeningLoading = false;
            state.selectedApplicationId = action.payload;
        },
        mainInitCategoriesUpdate: (state: InitState, action: PayloadAction<TransactionCategory>) => {
            if (state.main) {
                state.main.companyDetails.transactionCategories.push(action.payload);
            }
        },
        mainInitAllCardsUpdate: (state: InitState, action: PayloadAction<Card[]>) => {
            if (state.main) {
                state.main.companyDetails.cards = action.payload;
            }
        },
        mainInitUserGroupsUpdate: (state: InitState, action: PayloadAction<UserGroup>) => {
            if (state.main) {
                state.main.companyDetails.userGroups.push(action.payload);
            }
        },
        mainUpdateUserAuthApp: (state: InitState, action: PayloadAction<UserApp>) => {
            // get the user from state
            const user: User | null = state.main?.companyDetails?.user || null;
            if (user) {
                // set the new data
                user.authApp = action.payload;
            }
        },
        mainUpdateUserMobileApp: (state: InitState, action: PayloadAction<UserApp>) => {
            // get the user from state
            const user: User | null = state.main?.companyDetails?.user || null;
            if (user) {
                // set the new data
                user.mobileApp = action.payload;
            }
        },
        mainAddNewCard: (state: InitState, action: PayloadAction<UserApp>) => {
            // get the user from state
            const user: User | null = state.main?.companyDetails?.user || null;
            if (user) {
                // set the new data
                user.authApp = action.payload;
            }
        },
        mainInitAddCard: (state: InitState, action: PayloadAction<Card>) => {
            if (state.main) {
                state.main.companyDetails.cards.push(action.payload);
            }
        },
        mainInitUpdateCard: (state: InitState, action: PayloadAction<Card>) => {
            if (state.main) {
                const cardIndex = state.main.companyDetails.cards.findIndex((_cardId) => _cardId.id === action.payload.id);
                state.main.companyDetails.cards[cardIndex] = action.payload;
            }
        },

        setCanAccessCards: (state: InitState, action: PayloadAction<boolean>) => {
            state.canAccessCards = action.payload;
        },
        setCanAccessTerminals: (state: InitState, action: PayloadAction<boolean>) => {
            state.canAccessTerminals = action.payload;
        },
        setGlobalActiveDataType: (state: InitState, action: PayloadAction<string>) => {
            state.globalActiveDataType = action.payload;
        },
        setCompanyId: (state: InitState, action: PayloadAction<string>) => {
            state.selectedCompanyId = action.payload;
        },
        loginSuccess: (state: InitState) => {
            state.isLoggedIn = true;
        },
        mainUpdateUserAccount: (state: InitState, action: PayloadAction<UserAccount>) => {
            // replace UserAccount (action.payload) in state.main.companyDetails.accounts
            if (state.main) {
                const accountIndex = state.main.companyDetails.accounts.findIndex((_accountId) => _accountId.id === action.payload.id);
                state.main.companyDetails.accounts[accountIndex].activationStatus = action.payload.activationStatus;
            }
        },
        mainUpdateUserAccountCallback: (
            state: InitState,
            action: PayloadAction<{
                id: string;
                callback: (account: UserAccount) => void;
            }>
        ) => {
            // get the userAccount from state
            const userAccount: UserAccount | undefined = state.main?.companyDetails?.accounts.find((a) => a.id === action.payload.id);
            if (userAccount) {
                action.payload.callback(userAccount);
            }
        },
        mainUpdateCompanyDetails: (
            state: InitState,
            action: PayloadAction<{
                callback: (companyDetails: MainInitCompanyDetailsInterface) => void;
            }>
        ) => {
            // get the userAccountMeta from state
            if (state.main) {
                action.payload.callback(state.main.companyDetails);
            }
        },
        isSwitchingBusinessTrue: (state: InitState) => {
            state.isSwitchingBusiness = true;
        },
        isSwitchingBusinessFalse: (state: InitState) => {
            state.isSwitchingBusiness = false;
        },
        addNewCompany: (state: InitState, action: PayloadAction<CompanyInterface>) => {
            if (state.account) state.account.companies = [...state.account.companies, action.payload];
        },

        initReset: (state: InitState) => {
            state.main = null;
            state.error = null;
            state.account = null;
            state.loading = false;
            state.isLoggedIn = false;
            state.canAccessCards = false;
            state.selectedCountry = null;
            state.isAccountCreated = false;
            state.selectedCompanyId = null;
            state.canAccessTerminals = false;
            state.isSwitchingBusiness = false;
            state.globalActiveDataType = "";
            state.selectedApplicationId = null;
            state.isAccountOpeningLoading = false;
        },
    },
});

export const {
    setAccountCreated,
    initRequest,
    mainInitRequest,
    accountInitFailure,
    accountInitSuccess,
    mainInitFailure,
    mainInitSuccess,
    accountOpeningInitFailure,
    accountOpeningInitRequest,
    accountOpeningInitSuccess,
    initReset,
    setCompanyId,
    loginSuccess,
    setCanAccessCards,
    setCanAccessTerminals,
    mainInitCategoriesUpdate,
    mainInitAddCard,
    mainInitUpdateCard,
    mainInitAllCardsUpdate,
    mainInitUserGroupsUpdate,
    mainUpdateUserAuthApp,
    mainUpdateUserMobileApp,
    mainUpdateUserAccount,
    mainUpdateUserAccountCallback,
    mainUpdateCompanyDetails,
    // updateAccountOpeningProgress,
    isSwitchingBusinessTrue,
    isSwitchingBusinessFalse,
    addNewCompany,
    setSelectedCountry,
    setGlobalActiveDataType,
} = initSlice.actions;

export default initSlice.reducer;
