import { Link, useLocation } from "react-router-dom";

import ButtonComp from "../../../../button/ButtonComp";
import React from "react";
import { canShowPreReleaseFeatures } from "../../../../../utils/preReleaseConfig";

interface Props {
    path: string;
    text: string;
    isParentOpen: boolean;
    optionalPath?: string;
    isPreRelease?: boolean;
    onClick: (e: React.MouseEvent) => void;
}

function MenuItemChild({ onClick, path, text, isPreRelease = false, optionalPath, isParentOpen }: Props): JSX.Element {
    if (isPreRelease && !canShowPreReleaseFeatures) {
        return <></>;
    }

    const location = useLocation();

    const active = location.pathname.includes(optionalPath || path) || location.pathname.includes(path);

    return (
        <Link to={path} state={{ pageFrom: location.pathname }} onClick={onClick} tabIndex={isParentOpen ? 0 : -1}>
            <ButtonComp type="button" buttonType="tertiary" color="transparent" data-type="section" fullWidth>
                <div
                    className={
                        "relative flex h-10 w-full items-center justify-start rounded-md" +
                        ` ${active ? "bg-grey-tertiary" : "hover:bg-grey-tertiary"}`
                    }
                    data-type="section"
                    tabIndex={-1}
                >
                    <p
                        className={
                            "truncate pl-12 text-sm duration-150 " + ` ${active ? "font-medium text-black" : "text-black-secondary hover:text-black"}`
                        }
                        tabIndex={-1}
                    >
                        {text}
                    </p>
                </div>
            </ButtonComp>
        </Link>
    );
}

export default MenuItemChild;
