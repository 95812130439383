import { useCallback, useState } from "react";

import { TransferBetweenAccountInitRequest } from "../../../../services/send-money/transferBetweenAccountApi.types";
import { TransferBetweenResponse } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice.types";
import { errorTrue } from "../../../../../../../redux/app-toast/app-toast-slice";
import { getErrorMessage } from "../../../../../../../utils/getErrorMessage";
import { setSingleTransaction } from "../../../../../../../redux/transaction/slice/transactionSlice";
import { setTransferBetweenResponse } from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import { transferBetweenAccountInit } from "../../../../services/send-money/transferBetweenAccountApi";
import { useDispatch } from "react-redux";

interface UseTransferBetweenInterface {
    transferBetweenError: string;
    isTransferBetweenLoading: boolean;
    transferBetweenAccountResponse: TransferBetweenResponse | null;
    handleTransferBetween: (_data: TransferBetweenAccountInitRequest) => Promise<void>;
}

function useTransferBetween(): UseTransferBetweenInterface {
    const dispatch = useDispatch();
    const [transferBetweenError, setTransferBetweenError] = useState<string>("");
    const [isTransferBetweenLoading, setIsTransferBetweenLoading] = useState(false);
    const [transferBetweenAccountResponse, setTransferBetweenAccountResponse] = useState<TransferBetweenResponse | null>(null);

    const handleTransferBetween = useCallback(
        async (_data: TransferBetweenAccountInitRequest) => {
            try {
                setIsTransferBetweenLoading(true);
                setTransferBetweenError("");
                const res = await transferBetweenAccountInit(_data);
                setTransferBetweenAccountResponse({
                    transactionId: res.transaction.id,
                    isApproved: res.isApproved,
                    processing: res.processing,
                    accountsToActivate: res.accountsToActivate,
                });
                dispatch(
                    setTransferBetweenResponse({
                        transactionId: res.transaction.id,
                        isApproved: res.isApproved,
                        processing: res.processing,
                        accountsToActivate: res.accountsToActivate,
                    })
                );
                dispatch(setSingleTransaction(res.transaction));
            } catch (err) {
                const errorMessage = getErrorMessage(err);
                dispatch(errorTrue({ message: errorMessage }));
                setTransferBetweenError(errorMessage);
            } finally {
                setIsTransferBetweenLoading(false);
            }
        },
        [dispatch]
    );

    return {
        isTransferBetweenLoading,
        transferBetweenAccountResponse,
        transferBetweenError,
        handleTransferBetween,
    };
}

export default useTransferBetween;
