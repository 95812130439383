import {
    openAboutMyAccountModal,
    resetIndividualAccount,
    resetSubAccountData,
    setSubAccountModalStage,
} from "../../../../../redux/accounts/slice/accountsSlice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AboutMyAccountTray from "../../Components/Trays/AboutMyAccount";
import { AccountModalStageType } from "../../../../../redux/accounts/slice/accountsSlice.types";
import ButtonComp from "../../../../../components/button/ButtonComp";
import CreateSubAccountTray from "../../Components/Trays/CreateSubAccountTray";
import EditAccountDescription from "../../Components/Modals/EditAccountDescriptionModal";
import Header from "../../../../../components/Header";
import { IRootState } from "../../../../../redux/rootReducer";
import IndividualAccountChartCard from "../../Components/Cards/IndividualAccountChartCard";
import IndividualAccountsDetailsCard from "../../Components/Cards/IndividualAccountsDetailsCard";
import InflowCard from "../../Components/Cards/InflowCard";
import InfoTextButton from "../../../../../components/button/Info-text-button";
import PageLayout from "../../../../../components/layouts/page-layout";
import { PageTitle } from "../../../../../helpers/AppConstants";
import { ReactComponent as Plus } from "../../../../../assets/svg/Button/blue-circled-plus.svg";
import TabButton from "../../../../../components/TabButton";
import { abortIndividualAccountTransfer } from "../../Services/accounts-api";
import titleCase from "../../../../../hooks/titleCase";
import useIndividualAccount from "../../Hooks/State/useIndividualAccount";
import { useNavigate } from "react-router-dom";

function IndividualAccountDetails(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { inflow, outflow, pending, showAmount, handleToggleShowAmount } = useIndividualAccount();

    const [isLoading, setIsLoading] = useState(true);

    const main = useSelector((state: IRootState) => state.init.main);
    const isAdmin = useSelector((state: IRootState) => state.init.main?.companyDetails.user.isAdmin || false);
    const accountSummary = useSelector((state: IRootState) => state.account.individualAccountSummary);
    const individualAccount = useSelector((state: IRootState) => state.account.individualAccount);
    const subAccountModalStage = useSelector((state: IRootState) => state.account.subAccountModalStage);
    const companyAccountType = useSelector((state: IRootState) =>
        state.init.main?.meta.accountTypes.find((t) => t.type === state.init.main?.companyDetails.company.type)
    );

    const handleOpenCreateSubAccountModal = useCallback(() => {
        dispatch(setSubAccountModalStage(AccountModalStageType.DETAILS));
    }, []);

    const handleCloseCreateSubAccountModal = useCallback(() => {
        dispatch(resetSubAccountData());
    }, []);

    useEffect(() => {
        return () => {
            abortIndividualAccountTransfer();
            dispatch(resetIndividualAccount());
            dispatch(resetSubAccountData());
        };
    }, []);

    useEffect(() => {
        if (!individualAccount) {
            document.title = PageTitle.INDIVIDUAL_ACCOUNT_PAGE;
            return;
        }
        document.title = `${titleCase(individualAccount.accountName)} ${PageTitle.INDIVIDUAL_ACCOUNT_PAGE}`;
        setIsLoading(!(accountSummary && individualAccount));
    }, [accountSummary, individualAccount]);

    const handleOpenAboutMyAccountModal = useCallback(() => {
        dispatch(openAboutMyAccountModal());
    }, []);

    const handleGoBack = useCallback(() => {
        navigate("/accounts");
    }, []);

    return (
        <>
            <AboutMyAccountTray />

            <EditAccountDescription />

            <CreateSubAccountTray active={!!(subAccountModalStage === AccountModalStageType.DETAILS)} toggler={handleCloseCreateSubAccountModal} />

            <PageLayout
                pageTitle="Accounts"
                subTitle="List of your Lenco accounts"
                headerButtons={
                    companyAccountType?.subAccount &&
                    isAdmin && (
                        <>
                            <ButtonComp
                                color="grey"
                                type="button"
                                buttonType="secondary"
                                radiusSize="xs"
                                size="md"
                                func={handleOpenCreateSubAccountModal}
                            >
                                <div className="flex items-center justify-center space-x-1">
                                    <Plus className="stroke-current" />
                                    <span>Add Account</span>
                                </div>
                            </ButtonComp>
                        </>
                    )
                }
                isWithShadowContent
            >
                <div className="w-full space-y-4 pb-5">
                    {!(main?.companyDetails.company.isTypeStarter && main.companyDetails.accounts.length < 2) && (
                        <TabButton
                            isLoading={!individualAccount || !accountSummary}
                            buttons={[
                                {
                                    text: "Accounts",
                                    showSubTab: true,
                                    isActive: true,
                                    onClick: handleGoBack,
                                    subTab: {
                                        text: individualAccount?.bankAccount?.accountName || "",
                                        onClose: handleGoBack,
                                    },
                                },
                            ]}
                        />
                    )}

                    <div
                        className={`flex max-h-96 w-full flex-row items-start justify-between ${
                            !(main?.companyDetails.company.isTypeStarter && main.companyDetails.accounts.length < 2) ? "mt-4" : ""
                        }`}
                    >
                        <Header
                            isLoading={!individualAccount || !accountSummary}
                            text={individualAccount?.bankAccount?.accountName || ""}
                            sub_text={individualAccount?.description || "description"}
                        />

                        <InfoTextButton
                            isLoading={!individualAccount || !accountSummary}
                            onClick={handleOpenAboutMyAccountModal}
                            content={<span className="font-normal text-black">About My account</span>}
                        />
                    </div>
                    <div className="space-y-5">
                        <div className="grid grid-cols-1 items-stretch gap-5 xl:grid-cols-2">
                            <IndividualAccountsDetailsCard isLoading={isLoading} />
                            <IndividualAccountChartCard
                                isLoading={isLoading}
                                showAmount={showAmount}
                                accountSummary={accountSummary}
                                individualAccount={individualAccount}
                                handleToggleShowAmount={handleToggleShowAmount}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-y-5 xl:grid-cols-3 xl:gap-x-5 xl:gap-y-0">
                            <InflowCard isLoading={isLoading} transactions={inflow} type="Inflow" data-type="transaction" />
                            <InflowCard isLoading={isLoading} transactions={outflow} type="Payout" data-type="transaction" />
                            <InflowCard isLoading={isLoading} transactions={pending} type="Transfer" data-type="transaction" />
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    );
}

export default IndividualAccountDetails;
