import { Link, useLocation } from "react-router-dom";
import React, { useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../button/ButtonComp";
import GoogleIcon from "../../../../google-icon";
import { canShowPreReleaseFeatures } from "../../../../../utils/preReleaseConfig";

interface Props {
    icon: string;
    path: string;
    text: string;
    isNew?: boolean;
    hasSubRoutes?: boolean;
    isIconFilled?: boolean;
    isPreRelease?: boolean;
    isIconOutlined?: boolean;
    onClick: (e: React.MouseEvent) => void;
}

function MenuItem({
    path,
    icon,
    text,
    isNew = false,
    hasSubRoutes = false,
    isPreRelease = false,
    isIconFilled,
    isIconOutlined,
    onClick,
}: Props): JSX.Element {
    if (isPreRelease && !canShowPreReleaseFeatures) {
        return <></>;
    }

    const [active, setActive] = useState(false);
    const location = useLocation();

    useLayoutEffect(() => {
        setActive(location.pathname === path || (hasSubRoutes && location.pathname.includes(path)));
    }, [location.pathname, path]);

    return (
        <>
            <Link to={path} state={{ pageFrom: location.pathname }} onClick={onClick} data-type="section">
                <ButtonComp
                    type="button"
                    buttonType="tertiary"
                    color="transparent"
                    data-type="section"
                    onClick={() => setActive((prev) => !prev)}
                    noTabIndex
                    fullWidth
                >
                    <div
                        className={
                            "relative flex h-10 w-full items-center justify-start rounded-md px-4 " +
                            ` ${active ? "add-icon-fill bg-grey-tertiary" : "remove-icon-fill hover:bg-grey-tertiary"}`
                        }
                        data-type="section"
                        tabIndex={-1}
                    >
                        <GoogleIcon icon={icon} isFilled={isIconFilled} isOutlined={isIconOutlined} />

                        <span
                            className={
                                "ml-2 truncate text-sm duration-150 " +
                                ` ${active ? "font-medium text-black" : "text-black-secondary hover:text-black"}`
                            }
                            data-type="section"
                        >
                            {text}
                        </span>
                        {isNew && (
                            <div className="ml-2 select-none rounded bg-black px-1 py-0.5 leading-none">
                                <span className="text-xs font-medium text-white">New</span>
                            </div>
                        )}
                    </div>
                </ButtonComp>
            </Link>
        </>
    );
}

export default MenuItem;
