import * as Yup from "yup";

import { Form, Formik } from "formik";
import { SetPasswordForm, VerifyEmailData } from "../services/email-verification.types";

import ButtonComp from "../../../../components/button/ButtonComp";
import ErrorToast from "../../../../components/message-toast/sub/error-toast";
import GoogleIcon from "../../../../components/google-icon";
import LencoFormInput from "../../../../components/inputs/FormInput";
import { ReactComponent as Mailbox } from "../../../../assets/svg/mail-box.svg";
import PasswordHints from "../../../../utils/password-hints/password-hints";
import { PasswordRules } from "../../../../utils/password-hints/password-hints.service";
import useSetPassword from "../hooks/use-set-password";

interface Props {
    urlData: VerifyEmailData;
    onComplete: () => void;
}

function SetPassword(props: Props): JSX.Element {
    const { error, mutate, isPending } = useSetPassword({ onComplete: () => props.onComplete() });

    const initialFormState: SetPasswordForm = {
        password: "",
        repeatPassword: "",
    };

    const passwordValidation = Yup.string().required("Required");
    for (const rule of Object.values(PasswordRules)) {
        passwordValidation.matches(rule.regex, rule.text);
    }

    const formValidation = Yup.object().shape({
        password: passwordValidation,
        repeatPassword: Yup.string()
            .oneOf([Yup.ref("password"), undefined], "Password must match")
            .required("Confirm password is required"),
    });

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-md flex-col items-center justify-center rounded-lg bg-white p-4 3xs:p-8">
                <Formik
                    initialValues={initialFormState}
                    validationSchema={formValidation}
                    onSubmit={(values) => {
                        mutate({ ...props.urlData, ...values });
                    }}
                    enableReinitialize
                >
                    {(formik) => {
                        return (
                            <Form className="flex w-full flex-col items-center justify-start space-y-6" autoComplete="off">
                                <div className="flex w-full flex-col items-center justify-start space-y-8">
                                    <div>
                                        <Mailbox />
                                    </div>
                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                        <h3 className="text-center text-2xl font-medium text-black">Set your password</h3>
                                        <div className="flex w-full flex-col items-start justify-start space-y-4">
                                            {error && (
                                                <div className="mx-auto w-max pb-4">
                                                    <ErrorToast error={error} />
                                                </div>
                                            )}
                                            <LencoFormInput label="Choose a Password" name="password" type="password" />
                                            <PasswordHints
                                                password={formik.values.password}
                                                className="flex items-center space-x-2 pb-1 text-xs"
                                                show={formik.values.password.length > 0}
                                            />
                                            <LencoFormInput label="Re-enter Password" name="repeatPassword" type="password" required />
                                            {formik.values.repeatPassword.length > 0 && formik.values.repeatPassword !== formik.values.password && (
                                                <div>
                                                    <li className="flex items-center space-x-1 pb-2 text-xs">
                                                        <GoogleIcon icon="close" className="text-error" />
                                                        <span className="text-error">Password does not match</span>
                                                    </li>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-auto w-full max-w-md">
                                    <ButtonComp
                                        type="submit"
                                        color="black"
                                        buttonType="primary"
                                        disable={
                                            !formik.values.password ||
                                            !!formik.errors.password ||
                                            !formik.values.repeatPassword ||
                                            !!formik.errors.repeatPassword
                                        }
                                        isLoading={isPending}
                                        fullWidth
                                    >
                                        Set Password
                                    </ButtonComp>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
}

export default SetPassword;
