import { ButtonColor, getClass, getRippleColor, getSpinnerColor } from "./button.constant";

import LencoSpinner from "../spinner";
import React from "react";
import Ripples from "./Ripples";

export interface ButtonCompProps {
    href?: string;
    size?: "sm" | "md" | "lg" | "xl" | "2xl";
    type?: "button" | "submit" | "reset";
    color: ButtonColor;
    isText?: boolean;
    ripple?: "light" | "dark";
    disable?: boolean;
    children: React.ReactNode;
    dataType?: string | null;
    className?: string;
    isLoading?: boolean | null;
    fullWidth?: boolean;
    autoFocus?: boolean;
    noTabIndex?: boolean;
    buttonType?: "primary" | "secondary" | "tertiary" | "flat";
    fullBorder?: boolean;
    radiusSize?: "xs" | "sm" | "md" | "lg";
    paddingSize?: "2xs" | "xs" | "sm" | "md" | "lg";
    borderSmall?: boolean;

    func?: (e: React.MouseEvent) => void;
    onClick?: (e: React.MouseEvent) => void;
}

function ButtonComp(props: ButtonCompProps & React.HTMLAttributes<HTMLButtonElement>): JSX.Element {
    const {
        type = "button",
        children,
        disable = false,
        isLoading = false,
        fullWidth = false,
        buttonType,
        fullBorder = false,
        // radiusSize = "sm",
        borderSmall = false,

        func = undefined,
    } = props;

    return (
        <div
            className={
                `relative ` +
                ` ${disable ? "pointer-events-none cursor-default" : ""} ` +
                ` ${fullWidth ? "w-full" : "w-max"} ` +
                ` ${isLoading ? "pointer-events-none" : ""} ` +
                ` ${buttonType !== "tertiary" ? "overflow-hidden" : ""} `
            }
            data-type={props.dataType}
        >
            <Ripples
                color={getRippleColor(props)}
                borderSmall={borderSmall}
                fullBorder={fullBorder}
                size={props.size}
                show={!!(buttonType !== "tertiary")}
                dataType={props.dataType}
            >
                <button
                    className={getClass(props)}
                    type={props.disable ? "button" : type}
                    onClick={props.disable || props.type === "submit" ? undefined : func || props.onClick}
                    tabIndex={props.noTabIndex || props.disable ? -1 : 0}
                    data-type={props.dataType}
                    autoFocus={props.autoFocus}
                >
                    <div
                        className={
                            `absolute left-0 top-0 h-full w-full ` +
                            `${isLoading ? "flex" : "hidden"} ` +
                            `${props.buttonType === "tertiary" ? "items-start justify-start" : "items-center justify-center"} `
                        }
                        data-type={props.dataType}
                    >
                        <LencoSpinner color={getSpinnerColor(props.buttonType, props.color)} size="sm" />
                    </div>
                    <div
                        className={`flex w-full items-center justify-center font-medium ` + `${!isLoading ? "opacity-100" : "opacity-0"} `}
                        data-type={props.dataType}
                    >
                        {props.isText || typeof children === "string" ? <span data-type={props.dataType}>{children}</span> : children}
                    </div>
                </button>
            </Ripples>
        </div>
    );
}

export default ButtonComp;
