import ButtonComp from "../../../../components/button/ButtonComp";
import { Link } from "react-router-dom";
import { ReactComponent as Mailbox } from "../../../../assets/svg/mail-box.svg";

function EmailPreviouslyVerified(): JSX.Element {
    return (
        <>
            <div className="relative mx-auto flex w-full max-w-md flex-col items-center justify-center rounded-lg bg-white p-4 3xs:p-8">
                <div className="flex flex-col items-center justify-start space-y-6">
                    <div className="flex w-full flex-col items-center justify-start space-y-8">
                        <div>
                            <Mailbox />
                        </div>
                        <div className="flex w-full max-w-[360px] flex-col items-center justify-start space-y-4">
                            <h3 className="text-center text-2xl font-medium text-black">Your account has already been created.</h3>
                            <p className="text-center text-sm font-normal text-black-secondary">
                                Please check your email for the username you will require to login to your account.
                            </p>
                        </div>
                    </div>
                    <div className="mx-auto w-full max-w-md">
                        <Link to="/login">
                            <ButtonComp color="black" buttonType="primary" fullWidth>
                                <span>Login</span>
                            </ButtonComp>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default EmailPreviouslyVerified;
