import { SingleTransactionTableHead, headerData } from "../Components/TransactionTable/TableData/headerData";
import { TransactionFilter, TransactionsUrlParams } from "../Hooks/State/useTransactionsState.type";
import {
    resetAllTransactionData,
    setSelectedTransactionFilterStateInstant,
    setTransactionPaginationOffset,
} from "../../../../redux/transactions/slice/transactionsSlice";
import { useDispatch, useSelector } from "react-redux";

import EditTransactionAliasModal from "../Components/Modal/EditTransactionAliasModal";
import ExportZambiaTransactionsTray from "../Components/Tray/ExportZambiaTransactionsTray";
import { IRootState } from "../../../../redux/rootReducer";
import PageLayout from "../../../../components/layouts/page-layout";
import Table from "../../../../components/Table";
import { TableRecord } from "../../../../components/Table/Type/DataRow";
import { TextType } from "../../../../components/Table/Type/TextType";
import TransactionFilterSection from "../Components/Sections/TransactionFilterSection";
import { TransactionType } from "../../../../models/transaction.constants";
import { produce } from "immer";
import { setGlobalActiveDataType } from "../../../../redux/init/slice/initSlice";
import { setIsEditTransactionAliasModal } from "../../../../redux/accounts/slice/accountsSlice";
import { showTransactionDetails } from "../../../../redux/transaction/slice/transactionSlice";
import { useEffect } from "react";
import useFilterTransactionsState from "../Hooks/State/useFilterTransactionsState";
import { useParams } from "react-router";

function TransactionsPage(): JSX.Element {
    const { handleFilteredDetails, handleGetTransactions, handleClearAll } = useFilterTransactionsState();
    const dispatch = useDispatch();

    const main = useSelector((state: IRootState) => state.init.main);
    const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isZambia);
    const mainInitLoading = useSelector((state: IRootState) => state.init.mainInitLoading);
    const transactionsList = useSelector((state: IRootState) => state.transactions.transactionList);
    const isPaginationLoading = useSelector((state: IRootState) => state.transactions.isTransactionPaginationLoading);
    const transactionsListTotal = useSelector((state: IRootState) => state.transactions.transactionsListTotal);
    const transactionFilterState = useSelector((state: IRootState) => state.transactions.transactionFilterState);
    const transactionsListGroupSize = useSelector((state: IRootState) => state.transactions.transactionsListGroupSize);
    const isEditTransactionAliasModal = useSelector((state: IRootState) => state.account.isEditTransactionAliasModal);
    const transactionPaginationOffset = useSelector((state: IRootState) => state.transactions.transactionPaginationOffset);

    const isSingleAccount = main?.companyDetails.company.isTypeStarter && main.companyDetails.accounts.length < 2;

    const { type, query } = useParams<TransactionsUrlParams>();

    useEffect(() => {
        if (mainInitLoading) return;
        window.history.replaceState({}, "Transactions", "/transactions");
        if (!type && !query) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, offset: 0 });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        }
        if (type === TransactionFilter.INFLOW) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, transactionTypes: [TransactionType.CREDIT], offset: 0 });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        }
        if (type === TransactionFilter.PAYOUT) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, transactionTypes: [TransactionType.DEBIT], offset: 0 });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        }

        if (query && type === TransactionFilter.ACCOUNT) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, userAccountIds: [query], offset: 0 });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        }

        if (query && type === TransactionFilter.CARDS) {
            const updatedList = produce(transactionFilterState, (draft) => {
                return (draft = { ...draft, cardIds: [query], offset: 0 });
            });
            dispatch(setSelectedTransactionFilterStateInstant(updatedList));
            void handleGetTransactions(updatedList);
            return;
        }

        if (type === TransactionFilter.SEARCH && query) {
            const initialState = {
                offset: 0,
                date: { begin: "", end: "" },
                transactionTypes: [],
                userGroupIds: [],
                userAccountIds: [],
                categoryIds: [],
                transactionStatuses: [],
                cardIds: [],
                query: "",
            };

            dispatch(setSelectedTransactionFilterStateInstant({ ...initialState, query }));
            void handleGetTransactions({ ...initialState, query });
            return;
        }
    }, [type, query, mainInitLoading]);

    useEffect(() => {
        return () => {
            dispatch(resetAllTransactionData());
        };
    }, []);

    const handlePaginationRequest = (offset: number) => {
        const updatedList = produce(transactionFilterState, (draft) => {
            return (draft = { ...draft, offset });
        });
        dispatch(setSelectedTransactionFilterStateInstant(updatedList));
        dispatch(setTransactionPaginationOffset(offset));
        handleFilteredDetails({ offset });
    };

    const handleCloseIsEditTransactionAliasModal = () => {
        dispatch(setIsEditTransactionAliasModal(false));
    };

    console.log(
        transactionsList?.findIndex((_) => _.isCardPayment || !!_.origination?.card),
        transactionsList?.findIndex((_) => _.originatingUserAccount?.bankCode === _.destination?.userAccount?.bankCode)
    );

    return (
        <>
            {isZambia && <ExportZambiaTransactionsTray />}

            <EditTransactionAliasModal active={isEditTransactionAliasModal} toggler={handleCloseIsEditTransactionAliasModal} />

            {/* <DashboardLayout> */}
            <PageLayout pageTitle="Transactions" subTitle="List of transactions made on your Lenco accounts" isFullPageScroll>
                <div className="flex w-full flex-col items-start justify-start space-y-2 pb-6">
                    <TransactionFilterSection handleClearAll={handleClearAll} handleFilteredDetails={handleFilteredDetails} />
                </div>
                {/* <div className="relative flex w-full flex-grow">
                    <div className="absolute left-0 top-0 h-full w-full">
                        <div className="h-full w-full min-w-[898px]"> */}
                {/* <div className="test">
                    <table>
                        <thead>
                            <tr className="red">
                                <th>Name</th>
                                <th>Age</th>
                                <th>Job</th>
                                <th>Color</th>
                                <th>URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Lorem.</td>
                                <td>Ullam.</td>
                                <td>Vel.</td>
                                <td>At.</td>
                                <td>Quis.</td>
                            </tr>

                            <tr className="green">
                                <th>Name</th>
                                <th>Age</th>
                                <th>Job</th>
                                <th>Color</th>
                                <th>URL</th>
                            </tr>
                            <tr>
                                <td>Et?</td>
                                <td>Nemo.</td>
                                <td>Nisi?</td>
                                <td>Totam!</td>
                                <td>Voluptate.</td>
                            </tr>
                            <tr>
                                <td>Saepe?</td>
                                <td>Vero.</td>
                                <td>Amet?</td>
                                <td>Illo!</td>
                                <td>Laborum!</td>
                            </tr>
                            <tr className="purple">
                                <th>Name</th>
                                <th>Age</th>
                                <th>Job</th>
                                <th>Color</th>
                                <th>URL</th>
                            </tr>
                            <tr>
                                <td>Atque!</td>
                                <td>Tenetur.</td>
                                <td>Optio.</td>
                                <td>Iure.</td>
                                <td>Porro.</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
                <Table
                    isPaginateLoading={isPaginationLoading}
                    isLoading={isPaginationLoading}
                    heads={isSingleAccount ? SingleTransactionTableHead : headerData}
                    dataType="transaction"
                    total={transactionsListTotal}
                    offset={transactionPaginationOffset}
                    groupSize={transactionsListGroupSize}
                    paginateFunction={(_page, _offset) => handlePaginationRequest(_offset)}
                    isSticky
                    rows={
                        (transactionsList || []).map?.((trans) => ({
                            onRecordClick: () => {
                                dispatch(setGlobalActiveDataType("transaction"));
                                dispatch(showTransactionDetails(trans.id));
                            },
                            record: [
                                isSingleAccount
                                    ? undefined
                                    : // ? (...[])
                                      {
                                          key: "Account",
                                          text: trans.isCredit()
                                              ? trans.destination?.userAccount?.lencoNameMin ||
                                                trans.destination?.customerAccount?.bankAccount?.accountName ||
                                                trans.destination?.card?.name ||
                                                trans.destination?.narration
                                              : trans.origination?.userAccount?.lencoNameMin ||
                                                trans.origination?.customerAccount?.bankAccount?.accountName ||
                                                trans.origination?.card?.name ||
                                                trans.origination?.narration,
                                          subText: trans.singleDatetime,
                                          subTextType: TextType.DATE_TIME,
                                          gainTrend: trans.isCredit(),
                                      },
                                {
                                    key: "To/From",
                                    text: trans.isDebit()
                                        ? trans.destination?.userAccount?.lencoNameMin ||
                                          trans.destination?.customerAccount?.bankAccount?.accountName ||
                                          trans.destination?.card?.name ||
                                          trans.destination?.narration
                                        : trans.origination?.userAccount?.lencoNameMin ||
                                          trans.origination?.customerAccount?.bankAccount?.accountName ||
                                          trans.origination?.card?.name ||
                                          trans.origination?.narration,
                                    textType:
                                        trans.originatingUserAccount?.bankCode === trans.destination?.userAccount?.bankCode
                                            ? TextType.LENCO_LOGO
                                            : trans.isCardPayment || !!trans.origination?.card
                                              ? TextType.CARD_LOGO
                                              : undefined,
                                },
                                { key: "Type", text: trans.isDebit() ? "Payout" : "Inflow", gainTrend: trans.isCredit() },
                                {
                                    key: "Amount",
                                    currency: trans.currency,
                                    text: trans.amount,
                                    textType: trans.isDebit() ? TextType.FINAL_LOSS : TextType.FINAL_GAIN,
                                },
                                {
                                    key: "Balance",
                                    currency: trans.currency,
                                    text: trans.postTransactionBalance,
                                    textType: trans.isSuccess
                                        ? trans.postTransactionBalance
                                            ? TextType.BALANCE
                                            : TextType.BALANCE
                                        : trans.isFailed
                                          ? TextType.TRANSACTION_STATUS_FAILED
                                          : trans.isPendingApproval
                                            ? TextType.TRANSACTION_STATUS_PENDING_APPROVAL
                                            : trans.isDeclined
                                              ? TextType.TRANSACTION_STATUS_DECLINED
                                              : trans.isCancelled
                                                ? TextType.TRANSACTION_STATUS_CANCELLED
                                                : trans.isActivationPending
                                                  ? TextType.TRANSACTION_STATUS_PENDING_ACTIVATION
                                                  : trans.isProcessing
                                                    ? TextType.TRANSACTION_STATUS_PROCESSING
                                                    : TextType.BALANCE,
                                },
                            ],
                        })) as TableRecord
                    }
                />
                {/* </div>
                    </div>
                </div> */}
            </PageLayout>
        </>
    );
}

export default TransactionsPage;
