import AvatarDetails from "../../avatar-details";
import { ReactComponent as CardLogo } from "../../../assets/svg/card-logo.svg";
import { DataColumn } from "../Type/DataRow";
import LencoLogo from "../../../assets/svg/lenco-card-logo.png";
import { TextType } from "../Type/TextType";
import { formatDate } from "../../../utils/formatDate";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatTime } from "../../../utils/formatTime";

interface Props {
    dataColumn: DataColumn;
    dataType?: string;
}

export default function LogoFromText({ dataColumn, dataType }: Props) {
    return (
        <>
            {dataColumn.textType === TextType.ERROR ? (
                <p className="text-error">{typeof dataColumn.text === "string" ? dataColumn.text : ""}</p>
            ) : dataColumn.textType === TextType.LENCO_LOGO ? (
                <div className="flex items-center justify-start space-x-2">
                    <div className="h-8 w-8 min-w-[32px] max-w-[32px] overflow-hidden rounded-md">
                        <img src={LencoLogo} className="w-full" alt="lenco logo" />
                    </div>
                    <p className="w-full truncate text-left font-medium capitalize text-black">
                        {typeof dataColumn.text === "string" ? dataColumn.text : ""}
                    </p>
                </div>
            ) : dataColumn.textType === TextType.CARD_LOGO ? (
                <div className="flex items-center justify-start space-x-2">
                    <div className="h-8 w-8 min-w-[32px] max-w-[32px] overflow-hidden rounded-md">
                        <CardLogo className="h-full w-full" />
                    </div>
                    <p className="w-full truncate text-left font-medium capitalize text-black">
                        {typeof dataColumn.text === "string" ? dataColumn.text : ""}
                    </p>
                </div>
            ) : (
                <AvatarDetails
                    size="xs"
                    dataType={dataType}
                    title={
                        dataColumn.textType === TextType.DATE
                            ? formatDate(dataColumn.text as Date)
                            : dataColumn.textType === TextType.TIME
                              ? formatTime(dataColumn.text as Date)
                              : dataColumn.textType === TextType.DATE_TIME
                                ? formatDateAndTime(dataColumn.text as Date)
                                : typeof dataColumn.text === "string"
                                  ? dataColumn.text
                                  : ""
                    }
                />
            )}
        </>
    );
}
