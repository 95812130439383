import * as Yup from "yup";

import { Form, Formik } from "formik";
import { useCallback, useLayoutEffect, useState } from "react";

import ButtonComp from "../../../../../components/button/ButtonComp";
import ErrorToast from "../../../../../components/message-toast/sub/error-toast";
import FormInput from "../../../../../components/inputs/FormInput";
import LencoSpinner from "../../../../../components/spinner";
import MessageToast from "../../../../../components/message-toast";
import ReferenceSignatureComponent from "../componentss/ReferenceSignatureComponent";
import SuccessPage from "./success-page";
import titleCase from "../../../../../hooks/titleCase";
import { useParams } from "react-router";
import useReferenceFormState from "../hookss/State/useReferenceFormState";

interface referenceForm {
    corporateName: string;
    fullName: string;
    phone: string;
    address: string;
    bankName: string;
    branch: string;
    accountNumber: string;
}

type ReferenceUrlParams = {
    a: string;
    b: string;
};

function ReferenceForm(): JSX.Element {
    const {
        referenceFormError,
        referenceFormResponse,
        isReferenceFormLoading,
        verifyReferenceInviteError,
        verifyReferenceInviteResponse,
        isVerifyReferenceInviteLoading,

        handleVerifyInviteReferee,
        handleSubmitReferenceForm,
    } = useReferenceFormState();

    const { a, b } = useParams<ReferenceUrlParams>();

    const INITIAL_FORM_STATE: referenceForm = {
        corporateName: "",
        fullName: verifyReferenceInviteResponse?.referee.name || "",
        phone: verifyReferenceInviteResponse?.referee.phone || "",
        address: "",
        bankName: "",
        branch: "",
        accountNumber: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        corporateName: Yup.string().required("Required"),
        fullName: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        bankName: Yup.string().required("Required"),
        branch: Yup.string().required("Required"),
        accountNumber: Yup.string().required("Required"),
    });

    const [isRefereeDocumentSigned, setIsRefereeDocumentSigned] = useState(false);

    //determine link invite
    useLayoutEffect(() => {
        //check if params exists
        if (!a || !b) return;
        void handleVerifyInviteReferee({ a, b });
    }, [a, b]);

    const handleRefereeDocumentSigned = useCallback(() => {
        setIsRefereeDocumentSigned(true);
    }, []);

    return (
        <>
            <div
                className={
                    `flex min-h-screen w-full flex-col items-center justify-center bg-grey-backdrop p-3 ` +
                    `${isRefereeDocumentSigned ? "" : "px-8 lg:px-16"} `
                }
            >
                {!verifyReferenceInviteError && verifyReferenceInviteResponse && isRefereeDocumentSigned && (
                    <SuccessPage title="Referee" companyName={verifyReferenceInviteResponse.company} />
                )}
                {!verifyReferenceInviteError && !isRefereeDocumentSigned && verifyReferenceInviteResponse && (
                    <div className="flex w-full max-w-5xl flex-col items-center justify-start space-y-6 py-10">
                        <div className="flex w-full flex-col items-center justify-start space-y-6 border-b border-b-grey pb-6">
                            <h2 className="text-center text-3xl font-medium text-black">Reference Form</h2>
                            <div className="flex w-full flex-col items-start justify-start space-y-6">
                                <p className="text-black-secondary">Hello, {titleCase(verifyReferenceInviteResponse.referee.name)} </p>
                                <p className="text-black-secondary">
                                    {titleCase(verifyReferenceInviteResponse.creator)} of {titleCase(verifyReferenceInviteResponse.company)} has
                                    requested you to be a referee for their account opening with Lenco. Lenco is a banking platform for the modern
                                    business. Banking service is provided by Providus Bank
                                </p>
                                <p className="text-black-secondary">Kindly complete the reference form below:</p>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-8">
                            <div className="flex flex-col justify-between md:flex-row">
                                <div className="flex flex-col space-y-8">
                                    <div>
                                        <p className="text-black-secondary">To:</p>
                                        <p className="text-black-secondary">The Manager</p>
                                        <p className="text-black-secondary">Providus Bank PLC</p>
                                    </div>
                                    <div>
                                        <p className="text-black-secondary">Through:</p>
                                        <p className="text-black-secondary">Lenco Technology Limited</p>
                                    </div>
                                </div>
                                <div className="w-full max-w-80">
                                    <MessageToast
                                        className="!items-start"
                                        message={
                                            <>
                                                <p className="text-sm font-semibold text-warning">CAUTION:</p>
                                                <p className="text-sm text-warning">
                                                    It is dangerous to introduce an Individual/Corporate not well known to you
                                                </p>
                                            </>
                                        }
                                        fullWidth
                                        type="warning"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col space-y-6">
                                <p className="text-black-secondary">Dear Sir/Ma,</p>
                                <p className="text-black-secondary">
                                    <span className="font-bold uppercase">{verifyReferenceInviteResponse.company}</span> wishes to open a current
                                    account with you. They are well known to us and we consider them suitable to maintain this account with you. Below
                                    are our details;
                                </p>
                            </div>
                            <Formik
                                initialValues={{
                                    ...INITIAL_FORM_STATE,
                                }}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={(values) => {
                                    if (a && b) {
                                        void handleSubmitReferenceForm({ ...values, a, b, isCorporate: true });
                                    }
                                }}
                                enableReinitialize
                                validateOnMount
                            >
                                {(formik) => {
                                    return (
                                        <Form className="flex w-full flex-col items-center justify-start space-y-6">
                                            <div className="flex w-full flex-col rounded-lg bg-white p-6">
                                                {!referenceFormResponse && (
                                                    <div className="flex w-full flex-col items-center justify-start space-y-4">
                                                        {referenceFormError && <ErrorToast error={referenceFormError || ""} />}
                                                        <div className="flex w-full flex-col space-y-6">
                                                            <div className="flex flex-col items-start space-y-4">
                                                                <h5 className="text-sm font-medium text-black-secondary">Referee Details</h5>
                                                                <div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                                                                    <div className="lg:w-6/12">
                                                                        <FormInput
                                                                            type="text"
                                                                            name="corporateName"
                                                                            placeholder="Company Name"
                                                                            value={formik.values.corporateName}
                                                                        />
                                                                    </div>

                                                                    <div className="lg:w-6/12">
                                                                        <FormInput
                                                                            type="text"
                                                                            name="phone"
                                                                            placeholder="Your Phone Number"
                                                                            value={formik.values.phone}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <FormInput
                                                                    type="text"
                                                                    name="fullName"
                                                                    placeholder="Your Full Name"
                                                                    value={formik.values.fullName}
                                                                />
                                                                <FormInput
                                                                    type="textarea"
                                                                    name="address"
                                                                    placeholder="Company Address"
                                                                    value={formik.values.address}
                                                                />
                                                            </div>
                                                            <div className="flex flex-col items-start space-y-4">
                                                                <h5 className="text-sm font-medium text-black-secondary">Bank Details</h5>
                                                                <div className="my-4 grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                                                                    <FormInput
                                                                        type="text"
                                                                        name="bankName"
                                                                        placeholder="Bank Name"
                                                                        value={formik.values.bankName}
                                                                    />

                                                                    <FormInput
                                                                        type="text"
                                                                        name="branch"
                                                                        placeholder="Branch"
                                                                        value={formik.values.branch}
                                                                    />

                                                                    <FormInput
                                                                        type="text"
                                                                        name="accountNumber"
                                                                        placeholder="Account Number"
                                                                        value={formik.values.accountNumber}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {referenceFormResponse && (
                                                    <div className="flex w-full flex-col items-center justify-center space-y-8">
                                                        <div className="box-border w-full flex-col space-y-4 rounded-lg bg-grey-backdrop p-5">
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Name of Bank
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.bankName}
                                                                </p>
                                                            </div>
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Branch
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.branch}
                                                                </p>
                                                            </div>
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Account Number
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.accountNumber}
                                                                </p>
                                                            </div>
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Account Name
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.fullName}
                                                                </p>
                                                            </div>
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Phone Number
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.phone}
                                                                </p>
                                                            </div>
                                                            <div className="flex w-full flex-row items-start justify-between">
                                                                <p className="text-sm text-black-tertiary" data-type="transaction-active">
                                                                    Address
                                                                </p>
                                                                <p className="max-w-55% whitespace-normal break-words text-right text-sm font-medium text-black-secondary">
                                                                    {formik.values.address}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex w-full items-center justify-center">
                                                            {a && b && (
                                                                <ReferenceSignatureComponent
                                                                    a={a}
                                                                    b={b}
                                                                    handleRefereeDocumentSigned={handleRefereeDocumentSigned}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {!referenceFormResponse && (
                                                <ButtonComp
                                                    type="submit"
                                                    color="black"
                                                    ripple="light"
                                                    buttonType="primary"
                                                    disable={
                                                        !!(
                                                            formik.errors.corporateName ||
                                                            formik.errors.fullName ||
                                                            formik.errors.phone ||
                                                            formik.errors.address ||
                                                            formik.errors.bankName ||
                                                            formik.errors.branch ||
                                                            formik.errors.accountNumber
                                                        )
                                                    }
                                                    isLoading={isReferenceFormLoading}
                                                >
                                                    <span>Continue</span>
                                                </ButtonComp>
                                            )}
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                )}
                {isVerifyReferenceInviteLoading && (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <LencoSpinner />
                    </div>
                )}
                {verifyReferenceInviteError && (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                        <ErrorToast error={verifyReferenceInviteError} />
                    </div>
                )}
            </div>
        </>
    );
}

export default ReferenceForm;
